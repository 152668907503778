import LayoutF from "../../components/LayoutF";

const Cgu = () => {
  return (
    <LayoutF>
      <div className="bg-auth">
        <div className="container pt-24">
          <h4 className="bleu800">CONDITIONS GÉNÉRALES D'UTILISATION</h4>
          <p className="bleu800 subtitle-m mt-16">
            Les présentes conditions générales d'utilisation (dites « CGU ») ont
            pour objet l'encadrement juridique des modalités de mise à
            disposition du site et des services par MARION et de définir les
            conditions d’accès et d’utilisation des services par « l'Utilisateur
            ».
            <br />
            Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
            <br />
            Toute inscription ou utilisation du site implique l'acceptation sans
            aucune réserve ni restriction des présentes CGU par l’utilisateur.
            Lors de l'inscription sur le site via le Formulaire d’inscription,
            chaque utilisateur accepte expressément les présentes CGU en cochant
            la case précédant le texte suivant : « Je reconnais avoir lu et
            compris les CGU et je les accepte ».
            <br />
            En cas de non-acceptation des CGU stipulées dans le présent contrat,
            l'Utilisateur se doit de renoncer à l'accès des services proposés
            par le site.
            <br />
            https://www.app.marion-materiaux.fr se réserve le droit de modifier
            unilatéralement et à tout moment le contenu des présentes CGU.
          </p>
          <ol>
            <h5 className="bleu800 mt-40">
              <li className="text-start">
              ARTICLE 1 : LES MENTIONS LÉGALES
                <p className="bleu800-body-s mt-14">
                L'édition du site https://www.app.marion-materiaux.fr est assurée par la Société SAS MARION au
capital de 30000 euros, immatriculée au RCS de CANNES sous le numéro 987 734 472, dont le siège
social est situé au 559 Chemin du Hameau de Plan Sarrain
Adresse e-mail : contact@marion-materiaux.fr.
Le Directeur de la publication est : Sandra RONCE
L'hébergeur du site https://www.app.marion-materiaux.fr est la société OVH, dont le siège social est
situé au 2 rue Kellermann BP 80157 59053 ROUBAIX CEDEX 1.
                </p>
              </li>
            </h5>

            <h5 className="bleu800">
              <li className="text-start">ARTICLE 2 : ACCÈS AU SITE</li>
              <p className="bleu800-body-s mt-14">
              Le site https://www.app.marion-materiaux.fr permet à l'Utilisateur un accès gratuit aux services
suivants :
Vente et l’acheminement express de produits, matériaux et équipements pour le bâtiment à des
acheteurs professionnels

1

Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais
supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion
Internet, etc.) sont à sa charge.
L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire en remplissant
le formulaire. En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir
des informations sincères et exactes concernant son état civil et ses coordonnées, notamment son
adresse email.
Pour accéder aux services, l’Utilisateur doit ensuite s'identifier à l'aide de son identifiant et de son mot
de passe qu’il aura choisi.
Tout Utilisateur membre régulièrement inscrit pourra également solliciter sa désinscription en se
rendant à la page dédiée sur son espace personnel. Celle-ci sera effective dans un délai raisonnable.
Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site
ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas
la responsabilité de https://www.app.marion-materiaux.fr. Dans ces cas, l’Utilisateur accepte ainsi ne
pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis.
L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email de
l’éditeur communiqué à l’ARTICLE 1.
              </p>
            </h5>
            <h5 className="bleu800">
              <li className="text-start">ARTICLE 3 : COLLECTE DES DONNÉES</li>
              <p className="bleu800-body-s mt-14">
              Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect
de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et
aux libertés.
En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit
d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur
exerce ce droit :
· via son espace personnel .
              </p>
            </h5>
            <h5 className="bleu800">
              <li className="text-start">ARTICLE 4 : PROPRIÉTÉ INTELLECTUELLE</li>
              <p className="bleu800-body-s mt-14">
              Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son...) font l'objet d'une
protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.
La marque MARION est une marque déposée par Sandra RONCE.Toute représentation et/ou
reproduction et/ou exploitation partielle ou totale de cette marque, de quelque nature que ce soit, est
totalement prohibée.

L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie
des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement
privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.
Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation
expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2
et suivants du Code de la propriété intellectuelle.
Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui
reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.
              </p>
            </h5>
            <h5 className="bleu800">
              <li className="text-start">ARTICLE 5 : RESPONSABILITÉ</li>
              <p className="bleu800-body-s mt-14">
              Les sources des informations diffusées sur le site https://www.app.marion-materiaux.fr sont réputées
fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.
Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle.
Malgré des mises à jour régulières, le site https://www.app.marion-materiaux.fr ne peut être tenu
responsable de la modification des dispositions administratives et juridiques survenant après la
publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de
l’information contenue dans ce site.
L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que
soit sa forme, est interdite. Il assume les risques liés à l'utilisation de son identifiant et mot de passe. Le
site décline toute responsabilité.
Le site https://www.app.marion-materiaux.fr ne peut être tenu pour responsable d’éventuels virus qui
pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à
l’accès, ou au téléchargement provenant de ce site.
La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et
insurmontable d'un tiers.
              </p>
            </h5>
            <h5 className="bleu800">
              <li className="text-start">ARTICLE 7 : COOKIES</li>
              <p className="bleu800-body-s mt-14">
              Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces
liens, il sortira du site https://www.app.marion-materiaux.fr. Ce dernier n’a pas de contrôle sur les
pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur
contenu.
              </p>
            </h5>
            <h5 className="bleu800">
              <li className="text-start">ARTICLE 6 : LIENS HYPERTEXTES</li>
              <p className="bleu800-body-s mt-14">
              Lors de votre visite sur le Site, des cookies sont susceptibles d'être déposés votre ordinateur, votre
tablette ou smartphone.
Un « cookie » est un petit fichier texte qui contient des informations propres à l'utilisateur du Site. Il est
stocké sur le disque dur de l'internaute et peut être lu seulement par le serveur qui l'a fourni.
Par exemple, les cookies nous aident à nous souvenir de votre nom d'utilisateur lors de votre prochaine
visite, à comprendre vos interactions avec nos contenus et à améliorer ces derniers en fonction des
renseignements recueillis.
Les informations obtenues par les cookies sont enregistrées sur le serveur qui héberge le Site et qui est
situé en Europe.
<b>La finalité des cookies utilisés</b>
Le Vendeur fait un usage limité de « cookies » dans le but de faciliter votre navigation sur le Site,
d'optimiser la consultation du Site, de réaliser des mesures d'audience. Les cookies sont conservés pour
une durée maximale de 13 mois.
En l'espèce, il s'agit de :
• — cookies nécessaires au bon fonctionnement du Site :
Il s'agit des cookies indispensables au suivi et à l'enregistrement des Compte Client sur le Site. Sans ces
cookies, certains paramétrages du Site risquent de ne pas fonctionner correctement.
• — cookies de fonctionnalité :
Il s'agit des cookies indispensables à la navigation sur le Site. Il s'agit des cookies permettant de
mémoriser les choix faits lors des visites. Nous pouvons par exemple conserver la localisation
géographique dans un cookie afin de proposer des établissements ou des événements autour de cette
localisation.
• — cookies d'analyse de fréquentation :
Il s'agit de cookies permettant de suivre la navigation des internautes afin d'établir des statistiques de
consultation et suivre les performances du Site. Ces données nous permettent d'améliorer le Site et de
fournir une meilleure expérience utilisateur. En l'espèce, le dispositif est limité à la mesure d'audience et
à l'A/B testing et entrent dans le cadre spécifié par l'article 5 des lignes directrices relatives aux cookies
et autres traceurs. Si vous ne souhaitez pas que cette technologie soit utilisée, il vous est possible de
désactiver cette fonction dans votre logiciel de navigation tout en conservant un accès au Site. Cela
peut limiter votre capacité à utiliser/ bénéficier de certaines fonctionnalités de notre Site.
<b>La gestion des cookies</b>


À la première session des internautes sur le Site, un bandeau cookies apparaîtra en page d'accueil. Un
lien cliquable permet d'en savoir plus sur l'utilité et le fonctionnement des cookies. La poursuite de la
navigation sur une autre page du Site ou la sélection d'un élément du Site (image, lien, bouton "
rechercher "...) vaut acceptation de l'utilisation des cookies par les internautes.
À tout moment, vous pouvez choisir de désactiver les cookies. Votre navigateur peut également être
paramétré pour vous signaler les cookies qui sont déposés dans votre logiciel de navigation et vous
demandez de les accepter ou pas.
Vous pouvez accepter ou refuser les cookies au cas par cas ou bien les refuser systématiquement.
Nous rappelons que le paramétrage est susceptible de modifier les conditions d'accès à nos contenus
nécessitant l'utilisation de cookies. <b>Nous vous rappelons que la suppression de certains cookies est
susceptible de modifier vos conditions d'accès à notre Site.</b>
1 - Via les logiciels de navigation :
Chaque logiciel de navigation dispose de ses propres paramètres de gestion des cookies. Grâce à une
plateforme de gestion de cookies pour les cookies de partage et les cookies publicitaires :
La plateforme européenne Youronlinechoices, proposée par les professionnels de la publicité digitale,
regroupe les principaux acteurs européens de la publicité en ligne. Cette plateforme permet de
connaître les entreprises inscrites et offrent la possibilité de refuser ou d'accepter les Cookies utilisés
par ces entreprises pour adapter les publicités susceptibles d'être affichées sur votre terminal.
Le cookie déposé en cas d'utilisation des boutons de partage est également inclus dans cette
plateforme.
Attention, la prise en compte de votre souhait repose sur un cookie. Si vous supprimez tous les cookies
enregistrés dans votre terminal (via votre navigateur), les sociétés ne sauront pas que vous avez choisi
cette option.
3 - Cookies d'analyse de fréquentation :
Si vous ne souhaitez pas transmettre d'informations au Vendeur, faite la demande du module dans
votre navigateur Web à hello@breek.com
Pour obtenir plus d'information sur les cookies, vous pouvez consulter la page d'information de la
CNIL : <a href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/" className="link-s">http://www.cnil.fr/vos-droits/vos-traces/les-cookies/</a>
              </p>
            </h5>
            <h5 className="bleu800 pb-32">
              <li className="text-start">ARTICLE 8 : DROIT APPLICABLE ET JURIDICTION COMPÉTENTE</li>
              <p className="bleu800-body-s mt-14">
              La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un
litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.

Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux
coordonnées inscrites à l’ARTICLE 1.
              </p>
            </h5>
          </ol>
        </div>
      </div>
    </LayoutF>
  );
};
export default Cgu;

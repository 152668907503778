
import Footer from "./footer";
import Header from "./header";

const LayoutF = (props) => {
return(
    <div className="min-vh-10">
   
        <Header />
       <div className="col-12 min-vh-100">
        {props.children}
        </div> 
       <Footer/>
    </div>

)
}
export default LayoutF;
import Layout from "../../components/Layout";
import LayoutF from "../../components/LayoutF";
import FormLogin from "../../components/formLogin";
import { useMyContext } from "../../context";
import './auth.scss'
const Login = () => {
 
  return (
    <Layout header={true}>
      <div className="row m-0 pt-24">
        <div className="col-md-4"></div>
        <div className="col-md-4 login-form">
          {" "}
          <h4 className="bleu600">Se connecter</h4>
          <p className="bleu500">Connecte-toi pour accéder aux prix et vérifier la disponibilité des stocks</p>
          <p className="legend ">Pas encore de compte ? <a className="link-s" href='/register'> Créer son compte</a> </p>
          <FormLogin />
        </div>
        <div className="col-md-4"></div>
      </div>
    </Layout>
  );
};
export default Login;

import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

const ParisTime = () => {
  const [time, setTime] = useState('');

  useEffect(() => {
    const updateTime = () => {
      const parisTime = moment().tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss');
      setTime(parisTime);
    };

    updateTime();
    const intervalId = setInterval(updateTime, 1000); // Mise à jour toutes les secondes

    return () => clearInterval(intervalId); // Nettoyage à la suppression du composant
  }, []);

  return (
    <div>
      <h1>Heure actuelle à Paris :</h1>
      <p>{time}</p>
    </div>
  );
};

export default ParisTime;
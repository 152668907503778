import Logo from '../../assets/imgs/LogoMarion.svg';
import FormApropos from '../../components/Apropos';
import Layout from '../../components/Layout';
import LayoutF from '../../components/LayoutF';

import './auth.scss'
const Apropos=()=>{
return(
    <>
   
   <Layout header={true}>
    <div className="row bg-auth m-0 pt-70 a-propos min-vh-100">
        <div className="col-md-2"></div>
        <div className="container col">
           <h4>A propos de toi</h4>
        <p className='body-m mt-8'>Complète tes infos personnelles pour finir la création de ton compte.</p>
        <div className="form mt-32">
            <FormApropos/>
        </div>  
        </div>
        <div className="col-md-2"></div>
    </div>
    </Layout>
    </>
);
}
export default Apropos;
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement
} from '@stripe/react-stripe-js';

const AddCardElement = ({ setupIntent,handleCardAdded  }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const {client_secret: clientSecret} = setupIntent.setupIntent;
    const cardElement = elements.getElement(CardElement);
    try {
      stripe
        .confirmCardSetup(setupIntent.setupIntent, {
          payment_method: {
            card: cardElement,
          },
        })
        .then(function(result) {
          // Handle result.error or result.setupIntent
          handleCardAdded();
        });
    
    } catch (error) {
      console.error('Error confirming payment:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement  className="custom-input" options={{hidePostalCode:true}}/>
      <button type="submit" className='cta-primary-m mt-24' disabled={!stripe}>
        Ajouter Carte
      </button>
    </form>
  );
};

export default AddCardElement;

import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DisconnectModal = ({ openModal, setOpenModal }) => {
    const navigate=useNavigate()
  const handleYesClick = () => {
    // Clear local storage
    localStorage.clear();
    // Close the modal
    setOpenModal(false);
    navigate('/')
  };

  const handleNoClick = () => {
    // Close the modal
    setOpenModal(false);
  };

  return (
    <Modal show={openModal} onHide={handleNoClick} className="modal-marion">
      <Modal.Header closeButton>
        <Modal.Title><h6 className="text-center">Es-tu sûr de vouloir te déconnecter ?</h6></Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button className="cta-primary-s w-100" onClick={handleYesClick}>
          Je me déconnecte
        </Button>
        <Button className="cta-secondary-s w-100 mt-16" onClick={handleNoClick}>
          Annuler
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DisconnectModal;

import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import "../assets/css/filtre.scss";
import allFilter from "../assets/imgs/fi_filter.svg";
import Check from "../assets/imgs/fi_check.svg";
import Services from "../services/global.service";
import Arrow from "../assets/imgs/keyboard_arrow_down_24px.svg";
import Sort from "../assets/imgs/ic_round-sort.svg";
import { useParams } from "react-router-dom";
import { Dropdown, Button, FormCheck } from "react-bootstrap";

const Filtre = ({
  filterModal,
  setFilterModal,
  isChecked,
  setIsChecked,
  selectedOptions,
  setSelectedOptions,
  handleValider,
  sortingOption,
  setSortingOption,
  count,
  setCount,
  openFiltreOptions,
  setOpenFiltreOption,
  handleDeleteOption,
  handleDeleteMarque,
}) => {
  const [options, setOptions] = useState([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [initialSelectedOptions, setInitialSelectedOptions] = useState([]);
  const [resetTriggered, setResetTriggered] = useState(false);

  const params = useParams();

  const getMarques = async () => {
    try {
      const res = await Services.getOne(params.id, "marques/filtres");
      return res.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getOptions = async () => {
    try {
      const res = await Services.getOne(params.id, "products/options");
      const initialSelectedOptionsget = { marque: [], tri: [] };
      res.data.forEach((option) => {
        initialSelectedOptionsget[option.option] = [];
      });
      setInitialSelectedOptions(initialSelectedOptionsget);
      setSelectedOptions(initialSelectedOptionsget);
      console.log("initial selectedoption")
      const marquesData = await getMarques();
      const marquesDataToMerge = {
        option: "marque",
        values: marquesData.map((marque) => marque.name),
      };

      setOptions([marquesDataToMerge, ...res.data]);
    } catch (err) {
      console.error(err);
    }
  };

  const resetFilters = () => {
    setSelectedOptions(initialSelectedOptions);
    setCount(0);
    setResetTriggered(true);
  };

  const handleOptionSelect = (optionName, selectedValue) => {
    setSelectedOptions((prev) => {
      const isSelected = prev[optionName].includes(selectedValue);
      if (isSelected) {
        if (optionName !== "tri") {
          setCount(count - 1);
        }
        setResetTriggered(true);

        return {
          ...prev,
          [optionName]: prev[optionName].filter((value) => value !== selectedValue),
        };
        
      } else {
        if (optionName !== "tri") {
          setCount(count + 1);
        }
        return {
          ...prev,
          [optionName]: [...prev[optionName], selectedValue],
        };
      }
    });
  
  };
  const handleResetOld = (optionName) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [optionName]: [],
    }));
    
  };
  const handleReset = (optionName) => {
    setSelectedOptions((prev) => {
      const numSelected = prev[optionName].length;
      if (optionName !== "tri") {
        setCount(count - numSelected);
      }
      return {
        ...prev,
        [optionName]: [],
      };
    });
    setResetTriggered(true);
  };
  const openAllFilter = () => {
    setFilterModal(true);
  };
  useEffect(() => {
    if (resetTriggered) {
      handleValider();
      setResetTriggered(false);
    }
  }, [resetTriggered, handleValider]);
  useEffect(() => {
    getOptions();
  }, []);

  const toggleDropdown = (index) => {
    if (openDropdownIndex === index) {
      setOpenDropdownIndex(null);
    } else {
      setOpenDropdownIndex(index);
    }
  };

  const handleSwitchChange = async () => {
    await setIsChecked((prevState) => !prevState);
  };

  const toggleFiltreOptions = () => {
    setOpenFiltreOption(!openFiltreOptions);
  };

  return (
    <>
      <div className="row mt-32">
        <div className="col-auto mt-sm-12">
          <Dropdown >
            <Dropdown.Toggle className="select-filtre body-s" id="sortingDropdownSort">
              <img src={Sort} alt="sort icon" />{" "}
              <span className="body-s">Trier</span>{" "}
              <img
                src={Arrow}
                alt="arrow icon"
                className={`arrow-icon ${openDropdownIndex === -2 ? "open" : "closed"}`}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="select-options">
              <Dropdown.Item className="body-s">
              <div className="form-check">
              <label htmlFor="asc_label" className="form-check-label Exergue bleu700-body-s" onClick={() => {
                        setSortingOption("asc");
                        handleValider();
                      }} s> <input
                      type="radio"
                      name="sortingOption"
                      value="asc"
                      className="form-check-input "
                      id="asc_label"
                      checked={sortingOption === "asc"}
                      onChange={() => {
                        setSortingOption("asc");
                        handleValider();
                      }}
                    />
                  
                    
                    Par prix croissants
                  </label>
              </div>
              </Dropdown.Item>
              <Dropdown.Item className="body-s">
                <div className="form-check">
                  <label htmlFor="desc" className="form-check-label Exergue bleu700-body-s" onClick={() => {
                        setSortingOption("desc");
                        handleValider();
                      }}>
                    <input
                      type="radio"
                      name="sortingOption"
                      value="desc"
                      id="desc"
                      checked={sortingOption === "desc"}
                      onChange={() => {
                        setSortingOption("desc");
                        handleValider();
                      }}
                      className="form-check-input"
                    />
                    Par prix décroissants
                  </label>
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="body-s">
                <div className="form-check">
                  <label htmlFor="nouv" className="form-check-label Exergue bleu700-body-s" onClick={() => {
                        setSortingOption("");
                        handleValider();
                      }}>
                    <input
                      type="radio"
                      name="sortingOption"
                      value="nouv"
                      id="nouv"
                      checked={sortingOption === ""}
                      className="form-check-input"
                      onChange={() => {
                        setSortingOption("");
                        handleValider();
                      }}
                    />
                    Par nouveautés
                  </label>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {options?.slice(0, 5)?.map((option, index) => (
          <div key={index} className="col-auto mt-sm-12">
            <Dropdown autoClose={false} show={openDropdownIndex === index} onToggle={() => toggleDropdown(index)}>
              <Dropdown.Toggle className="select-filtre Exergue bleu700-body-s" id={`dropdownMenu${index}`}>
                <div className="body-s d-flex">
                  {option.option === "marque" ? "Marque" : option.option}{" "}
                  {selectedOptions[option.option]?.length > 0 && (
                    <span className="selectedCount">
                      {selectedOptions[option.option]?.length}
                    </span>
                  )}{" "}
                  <img
                    src={Arrow}
                    alt="arrow icon"
                    className={`arrow-icon ${openDropdownIndex === index ? "open" : "closed"}`}
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="select-options">
                {option.values.map((val, subIndex) => (
                  <Dropdown.Item
                    key={subIndex}
                    className="Exergue bleu700-body-s d-flex justify-content-between"
                    onClick={() => handleOptionSelect(option.option, val)}
                  >
                    {val}{" "}
                    {selectedOptions[option.option]?.includes(val) && (
                      <img src={Check} alt="check icon" className="text-end" />
                    )}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                {selectedOptions[option.option]?.length > 0 ? (
                  <div className="dropdown-buttons">
                    <button
                      className="cta-primary-m w-100 mt-24"
                      onClick={() => {
                        toggleDropdown(index);
                        handleValider();
                      }}
                    >
                      Valider
                    </button>
                    <button
                      className="cta-secondary-m mt-8 w-100"
                      onClick={() => 
                        {
                          handleReset(option.option)
                          handleValider()
                        }
                      }
                    >
                      Rénitialiser
                    </button>
                  </div>
                ) : (
                  <div className="dropdown-buttons">
                    <button
                      className="cta-primary-m w-100 mt-24"
                      onClick={() => toggleDropdown(index)}
                    >
                      Fermer
                    </button>
                  </div>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ))}

        <div className="col-auto mt-sm-12">
          <button className="all-filter d-flex" onClick={openAllFilter}>
            <img src={allFilter} className="mr-8" alt="all filter icon" />
            Tous les filtres
            {count > 0 && <span className="all-counted-filter"> {count}</span>}
          </button>
        </div>
      </div>
      {count > 0 && (
        <div className="row mt-16 align-items-center">
          <div className="col-auto mt-sm-12">
            {Object.entries(selectedOptions).map(
              ([option, values]) =>
                values.length > 0 &&
                values.slice(0, 2).map(
                  (value) =>
                    value && (
                      <span key={value} className="selectedFilter mr-16">
                        {value}{" "}
                        <button
                          className="link-s"
                          onClick={() => handleDeleteOption(option, value)}
                        >
                          x
                        </button>
                      </span>
                    )
                )
            )}
          </div>
          {count - 5 > 0 && (
            <div className="col-auto mt-sm-12">
              <button
                className="cta-primary-s moreFiltres"
                onClick={toggleFiltreOptions}
              >
                + {count - 5}
              </button>
            </div>
          )}

          {count > 0 ? (
            <div className="col-auto mt-sm-12">
              <button className="link-s" onClick={resetFilters}>
                Tout effacer
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <div className="row mt-32">
        <div className="col">
          <div className="custom-switch custom-switch-label-io d-flex align-items-center">
            <FormCheck
              type="switch"
              id="command-direct"
              className="mr-8"
              checked={isChecked}
              onChange={handleSwitchChange}
            />
            <label className="bleu600 legend" htmlFor="command-direct">
              Afficher seulement les produits en stock
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filtre;

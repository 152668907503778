import React, { createContext, useContext, useEffect, useState } from 'react';
import AuthService from './services/auth.service';

// Create a context
const MyContext = createContext();

// Create a provider component
export const MyProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [cartData, setCartData] = useState(null);
//   const GetProfil=()=>{
//     AuthService.profil()
//     .then((res)=>{userData(res.data.user)})
//     .catch((err)=>{})
//   }
//   useEffect(()=>{GetProfil()},[])
  return <MyContext.Provider value={{ userData, setUserData , cartData, setCartData }}>{children}</MyContext.Provider>;
};

// Custom hook to use the context value
export const useMyContext = () => useContext(MyContext);

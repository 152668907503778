import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import Eye from '../assets/imgs/fi_eye.svg'
import EyeClosed from '../assets/imgs/fi_eye-off.svg'
import ErrorIcon from '../assets/imgs/fi_alert-triangle.svg'
import ArrowRight from '../assets/imgs/fi_arrow-right.svg'
import Info from '../assets/imgs/fi_info.svg'
import AuthService from "../services/auth.service";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Email invalide").required("Email requis"),
  password: Yup.string()
    .required("Mot de passe requis")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/,
      "Le mot de passe doit contenir au moins 8 caractères, une majuscule et un caractère spécial."
    ),
});

const FormRegister = () => {
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Added showPassword state
  const navigate = useNavigate();

  const handleToggle = () => { // Added handleToggle function
    setShowPassword(!showPassword);
  };

  const handleSubmit = (values) => {
    const { email, password } = values;
    AuthService.checkMail(email).then(
      (response) => {
        if (response?.status === 1) {
          navigate("/a-propos", { state: { email, password } });
        } else {
          setError("Email existe déjà");
        }
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

  return (
    <div className="login">
      <div className="form-login">
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="mt-24">
                <div className="form-floating mb-3">
                  <Field
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${touched.email && errors.email ? "danger-input" : ""}`}
                    id="floatingInput"
                    placeholder=""
                  />
                  <label htmlFor="floatingInput">Adresse e-mail</label>
                  {touched.email && errors.email && <div className="text-start mt-8 d-flex"> <img src={ErrorIcon} className="mr-8" alt="error icon"/>
                   <span className="text-danger-custom">{errors.email}</span></div>}
                 
                </div>
              </div>

              <div className=" password-input-container form-floating mb-3 flex-grow-1">
                <Field
                  type={showPassword ? "text" : "password"} // Changed type based on showPassword state
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${touched.password && errors.password ? "danger-input" : ""}`}
                  id="password"
                  placeholder=""
                />
                <label htmlFor="password">Mot de passe</label>
                <div className="password-toggle-icon">
                  <img src={showPassword ? Eye : EyeClosed} className="password-icon" alt="password toggle" onClick={handleToggle} /> {/* Added onClick handler */}
                </div>
               
              </div>
               {touched.password && errors.password &&<div className="text-start d-flex mt-8"> <img src={ErrorIcon} className="mr-8" alt="error icon"/>
               <span className="text-danger-custom">{errors.password}</span></div>}
              <div className="mt-8 d-flex align-items-start info">
                <img src={Info} alt='note icon' className="mr-12"/><p>
                  Le mot de passe doit contenir au moins : 8 caractères, une majuscule et un caractère spécial
                </p>
              </div>

              <div>
                <span className="text-danger text-start pt-5">{error}</span>
                <button type="submit" className="cta-primary-m mt-24 w-100">
                  Je m’inscris   <img src={ArrowRight} className="ml-8" alt ="arrow right"/>
                </button>
              </div>

              <div className="mt-16">
                <label className="cond-gen">
                  En cliquant sur "Je m'inscris", vous déclarez avoir lu, compris et accepté nos <a href="/cgu" id="links-style">Conditions Générales d'Utilisation</a>, 
                  nos <a href="/cgv" id="links-style">Conditions Générales de Vente </a>ainsi que notre <a href="/politique" id="links-style">Politique de Confidentialité</a>.
                </label>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default FormRegister;

import LayoutF from "../../components/LayoutF";
const Cgv = () => {
  return (
    <LayoutF>
      <div className="bg-auth">
        <div className="container pt-24">
          <h4 className="text-dark">CONDITIONS GÉNÉRALES DE VENTE</h4>
          <h4 className="text-dark">PRÉAMBULE</h4>
          <p className="bleu800 subtitle-m mt-16">
            <b>
              L’application mobile/ site internet, ci-après dénommée{" "}
              <b>«le Service »</b>, est éditée par la société MARION (dénommée{" "}
              <b>« le Vendeur »</b> dans le cadre des présentes) SAS au capital
              de 30 000 euros, immatriculée au RCS de CANNES sous le numéro
              987734472 et dont le siège social est sis 559 Chemin du Hameau de
              Plan Sarrain 06370 MOUANS-SARTOUX.
            </b>
            <br />

            <b>
              MARION est une application mobile/ site internet ayant pour objet
              de proposer la vente et l’acheminement express des produits,
              matériaux et équipements pour le bâtiment (ci- après dénommés{" "}
              <b>« les Produits »</b>) à des acheteurs professionnels (ci-après
              dénommé <b>«le(s) Client(s) »</b> ).
            </b>
          </p>
          <br />

          <h4 className="text-dark margin-16">DEFINITIONS</h4>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Dans le cadre des présentes conditions générales de vente, les
            termes ci-après doivent s’entendre dans le sens des définitions
            suivantes :<br />
            <b>« CGV »</b> désigne les présentes Conditions Générales de Vente ;{" "}
            <br />
            <b>« Client »</b> désigne, toute personne physique ou morale,
            publique ou privée, qui agit à des fins entrant dans le cadre de son
            activité commerciale, industrielle, artisanale, libérale ou
            agricole, y compris lorsqu’elle agit au nom et pour le compte d’un
            autre professionnel au sens de l’article liminaire du Code de la
            consommation et qui passe Commande auprès du Vendeur; <br />
            <b>« Commande »</b> désigne une demande d’achat effectuée par le
            Client et qui a été acceptée/confirmée par le Vendeur; <br />
            <b>« Compte client »</b> : désigne le compte personnel créé par le
            Client sur le Site lui permettant de passer Commande ;<br />
            <b>« Destinataire » </b> désigne la personne physique ou morale,
            mentionnée par le Client ou par son représentant, qui réceptionne
            les Produits et à laquelle la Livraison est faite ;<br />
            <b>« Distributeur » </b> désigne la personne morale qui distribue
            dans ses points de vente et magasins le(s Produit(s) mis en ligne
            sur le Site ;<br />
            <b>« Livraison » </b> désigne la remise physique du ou des Produits
            au(x) Client(s) ou à son représentant qui l’accepte;
            <br />
            <b>« Partie(s) » </b> désigne le Vendeur et/ou le Client; <br />
            <b>« Prix » </b> désigne le tarif du ou des Produits fixé par le
            Vendeur et accepté par le Client dans une Commande; <br />
            <b>« Produit » </b> désigne les objets, produits, matériaux et
            équipements pour le bâtiment qui font l’objet de la Commande;
            <br />
            <b>« Vendeur» » </b> désigne la société Marion Ces définitions
            couvrent à la fois les formes singulières et plurielles des termes
            définis.
          </p>

          <h4 className="text-dark margin-16">1. Principes généraux</h4>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Les présentes Conditions Générales de Vente définissent les
            modalités des ventes en ligne conclues entre le Vendeur et tout
            Client effectuant un achat via le Service.
            <br />
            Elles sont rédigées en français dans leur version originale qui
            seule fait foi et prévaut sur toute autre version. Les présentes
            Conditions Générales de Vente s'appliquent à l'exclusion de toutes
            autres conditions. Elles sont accessibles par le Client sur le
            Service à tout moment et elles sont systématiquement soumises au
            Client avant toute commande et au moment de l'enregistrement de la
            commande. <br />
            Lors de la commande, le fait de cliquer sur le bouton « J'accepte
            les conditions générales de vente » manifeste le consentement du
            Client à l'application des présentes conditions générales de vente.
            <br />
            En conséquence, le fait de passer commande implique l'adhésion
            entière et sans réserve du Client à ces Conditions Générales de
            Vente.
            <br />
            Le fait de ne pas exercer, à un moment quelconque, une prérogative
            reconnue par les présentes Conditions générales de vente, ou de ne
            pas exiger l'application d'une stipulation quelconque de la
            convention issue desdites Conditions ne pourra en aucun cas être
            interprétée, ni comme une modification du contrat, ni comme une
            renonciation expresse ou tacite au droit d'exercer ladite
            prérogative dans l'avenir, ou au droit d'exiger l'exécution
            scrupuleuse des engagements souscrits aux présentes.
            <br />
            Dans l'hypothèse où l'un quelconque des termes des Conditions
            Générales de Vente serait considéré comme illégal ou inopposable par
            une décision de justice, les autres dispositions resteront en
            vigueur.
          </p>

          <h4 className="text-dark margin-16">
            2. Modification des Conditions Générales de Vente en ligne
          </h4>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Compte tenu des évolutions possibles du Service, le Vendeur se
            réserve la possibilité d'adapter ou de modifier à tout moment les
            présentes Conditions Générales de Vente. En conséquence, le Vendeur
            invite le Client à consulter les Conditions Générales de Vente avant
            toute nouvelle commande. Les Conditions Générales de Vente alors en
            vigueur seront applicables à toutes les commandes passées à compter
            de leur date de mise en ligne.
          </p>
          <h4 className="text-dark margin-16">
            3. Majorité légale et capacité
          </h4>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Le Client déclare être juridiquement capable de conclure le présent
            Contrat, dont les Conditions Générales de Vente sont présentées
            ci-après, c'est-à-dire avoir la majorité légale et ne pas être sous
            tutelle ou curatelle.
          </p>
          <h4 className="text-dark margin-16">4. Produits</h4>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Les caractéristiques essentielles des Produits sont décrites pour
            chaque Produit sur le catalogue en ligne. Elles ont été présentées
            au Client préalablement à la commande, dans le cadre des
            informations précontractuelles qui lui ont été communiquées.
          </p>
          <h6 className="text-dark margin-16">
            4.1. Caractéristiques des Produits
          </h6>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            La liste des Produits présentés sur le Service est susceptible
            d'évoluer à tout moment pour mieux répondre aux besoins du Client.
            <br />
            Les caractéristiques principales des Produits et notamment les
            spécifications, illustrations et indications de dimensions ou de
            capacité des Produits, sont présentées sur le Service et sont
            susceptibles d'être modifiées à tout moment.
            <br />
            Le Client est tenu de se reporter au descriptif de chaque Produit
            afin d'en connaître les propriétés et les particularités
            essentielles. Le Client est tenu d'en prendre connaissance avant
            toute passation de commande, le choix et l'achat d'un Produit
            relevant de la seule responsabilité du Client.
            <br />
            Pour les fournitures de certains matériaux (notamment : carrelage,
            pierre reconstituée et pierre naturelle, bois et produits intégrant
            du bois....), les dimensions, nuances de couleur, aspect, structure,
            poids peuvent être soumis à des variations inhérentes à leur
            fabrication ou à leur nature, entrant dans les tolérances d'usage.
            Le stockage et la pose de bois ou produits comportant du bois
            nécessitent de contrôler l'hygrométrie de l'atmosphère, du support
            et du produit.
          </p>
          <h6 className="text-dark margin-16">4.2. Utilisation des Produits</h6>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Avant utilisation du Produit, le Client prend connaissance et veille
            à appliquer les précautions d'emploi et la notice d'utilisation, en
            particulier pour les Produits classés dangereux, et prend
            connaissance de la date limite d'utilisation pour les Produits
            périssables.
          </p>
          <h6 className="text-dark margin-16">
            4.3. Conformité aux dispositions légales et réglementaires –
            Exportation
          </h6>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Les Produits sont conformes aux dispositions légales et
            réglementaires applicables en France. Il appartient au Client, qui
            en est seul responsable, de vérifier la conformité du Produit en cas
            d'exportation ou d'utilisation du Produit hors de France.
          </p>
          <h6 className="text-dark margin-16">5. Compte Client</h6>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Afin de passer commande en ligne sur le Service, il est nécessaire
            de créer un Compte Client. Pour la création de son compte, il sera
            demandé au Client d'indiquer son nom, prénom, adresse de
            facturation, numéro de téléphone et le cas échéant numéro de SIRET
            et numéro de TVA intracommunautaire. À l'issue de la création de son
            Compte Client, un e-mail de confirmation récapitulant les
            informations renseignées est envoyé au Client sur l'adresse qu'il
            aura préalablement renseignée.
            <br />
            L'identifiant de connexion est l'adresse e-mail du Client. L'accès
            au Compte Client est protégé par un mot de passe personnel et
            confidentiel. Le Client s'engage à le conserver secret et à ne le
            communiquer à des tiers à quelque titre que ce soit. Le Client est
            responsable de son mot de passe. S'il s'aperçoit que son compte fait
            l'objet d'une utilisation frauduleuse, le Client s'engage à le
            signaler immédiatement au Vendeur et à modifier son mot de passe.
          </p>
          <h6 className="text-dark margin-16"> 6. Commande</h6>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Le Client a reçu préalablement à la commande les informations visées
            à l'article L.111-1 du Code de la consommation, et notamment les
            caractéristiques essentielles des Produits ainsi que leur prix. Le
            Client effectue sa commande à partir du catalogue en ligne du
            Vendeur accessible sur le Service. Il est précisé que toute commande
            effectuée sur le Service est une commande avec obligation de
            paiement. Toute commande vaut acceptation de la description des
            Produits et des prix en vigueur au jour de la commande. Pour passer
            une commande en ligne sur le Service, le Client doit :
            <ul>
              <li>
                {" "}
                s'identifier avec son e-mail et son mot de passe s'il possède
                déjà un Compte Client, ou créer un Compte Client ;
              </li>
              <li> renseigner son adresse de livraison</li>
              <li>remplir son panier avec un ou plusieurs Produits ;</li>
            </ul>
            Une fois les Produits sélectionnés, vous pouvez consulter les
            articles de votre panier en cliquant sur «Panier ».Une fois son
            panier remplit, le Client a la possibilité de vérifier les Produits
            commandés, le prix total de sa commande et les conditions de sa
            commande. Il choisit son mode de paiement. Le récapitulatif du
            panier vous permet d’ajouter des quantités de Produits ou de
            supprimer les Produits sélectionnés, puis de cliquer sur « Mettre à
            jour le panier » ou de poursuivre vos achats en cliquant sur «
            Commander » La commande ne sera définitivement enregistrée qu'à la
            dernière validation de l'écran récapitulatif de la commande. Cette
            action est assimilée à la signature manuscrite visée à l'article
            1367 du code civil et à la conclusion d'un contrat sous forme
            électronique au sens des articles 1127-1 et 1127-2 du code civil
            français. À compter de cette action :
            <ul>
              <li>
                le Client confirme sa commande et déclare accepter celle-ci,
                ainsi que l'intégralité des présentes Conditions Générales de
                Vente pleinement et sans réserve ;
              </li>
              <li>
                la commande est considérée comme irrévocable et ne peut être
                remise en cause que dans les cas limitativement prévus dans les
                présentes.
              </li>
            </ul>
          </p>

          <h6 className="text-dark margin-16">
            7. Confirmation de la commande
          </h6>
          <p className="bleu800 subtitle-m mt-16 margin-32 ">
            A l’issue de l'enregistrement d’une demande d’achat par le Client
            sur le Service et si cette demande est acceptée par le Vendeur, un
            accusé de réception détaillé de celle-ci lui est envoyé à son
            adresse e-mail qu'il aura préalablement renseignée. Cet accusé de
            réception précise le montant exact facturé, l'indication des
            produits commandés et leur quantité, les modalités de livraison de
            la commande et renvoie aux présentes Conditions Générales de Vente.
            Cet accusé de réception vaut acceptation de la commande par le
            Vendeur et validera la transaction. Le Client accepte que les
            systèmes d'enregistrement de la commande valent preuve de l'achat et
            de sa date. En conservant cet e-mail et/ou en l'imprimant, le Client
            détient une preuve de sa commande que le Vendeur lui recommande de
            conserver.
            <br />
            Cet e-mail confirme que la commande du Client a été prise en compte
            par le Vendeur et non que le Produit commandé est disponible.
            <br />
            Le Vendeur s'engage à honorer les commandes en ligne uniquement dans
            la limite des stocks disponibles. À défaut de disponibilité, le
            Vendeur s'engage à en informer au plus vite le Client par courrier
            électronique ou par téléphone. Le Client a la faculté, en cas
            d'indisponibilité du Produit commandé, soit d'annuler, soit de
            modifier sa commande. Dans l’hypothèse où un ou plusieurs Produits
            serai(en)t en rupture de stock, le Client en est informé par email
            et le montant des Produits concernés est immédiatement remboursé sur
            son compte.
          </p>
          <h4 className="text-dark margin-16">8. Prix</h4>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Les prix de vente des Produits sont ceux figurant sur le Service au
            moment de la commande. Tout changement de prix postérieur à la
            commande est sans effet sur celle-ci. <br />
            Les prix de vente des produits peuvent être modifiés à tout moment
            par le Vendeur sur le Service. Les prix pourront être révisés
            notamment en fonction de l'évolution des tarifs de nos fournisseurs,
            de notre politique commerciale, des variations résultant des coûts
            de main d'œuvre, du cours des matières premières et des frais de
            transport.
            <br />
            Les prix des produits sont indiqués en euros hors taxes et peuvent
            être soumis à des conditions ou à une durée de validité limitée. Le
            taux de la TVA applicable est celui en vigueur au moment de la
            passation de la Commande.
            <br />
            Le montant de tout éventuelle éco-contribution ou éco-participation
            est indiqué sous le prix unitaire du produit concerné.
            <br />
            Le prix total de la Commande indiqué dans le mail de confirmation
            comprend le montant de la ou des éco-
            contribution/éco-participation.
            <br />
            Les prix annoncés ne tiennent pas compte des frais de livraison qui
            sont à la charge du Client, conformément à l'article 10 ci-dessous.{" "}
            <br />
            En cas de différence tarifaire entre le Service et le catalogue (ou
            tout autre support de communication) du Vendeur, le prix applicable
            est celui indiqué sur le Service.
            <br />
            En cas d'erreur typographique sur le prix d'un Produit sur le
            Service, ou de prix manifestement erroné ou dérisoire, le Vendeur
            signalera aux Clients concernés ladite erreur et pourra annuler la
            commande du (des) Produit(s). <br />
            Les offres promotionnelles ne sont valables que dans la double
            limite de la durée de validité de l'offre concernée et des stocks
            disponibles.
          </p>
          <h4 className="text-dark margin-16">9. Paiement</h4>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Le paiement de la commande s'entend comptant au jour de la commande
            sur le site. Constitue un paiement au sens du présent article la
            mise effective des fonds à la disposition du Vendeur.
          </p>
          <h6 className="text-dark margin-16">9.1 – Modalités de paiement</h6>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Le Client devra procéder au paiement en euros de la totalité du prix
            de sa commande par carte bancaire, sans escompte et au comptant,
            afin de valider cette dernière. Le montant à régler est celui
            indiqué dans le récapitulatif de commande (avant validation de
            celle-ci) ; il est rappelé dans la confirmation de commande adressée
            au Client à l'issue du processus de commande. Une facture sera
            également adressée au Client par e-mail. Les Clients ont le choix
            entre deux modes de paiement lors de la passation de commande :
            <br />
            <b>a) Paiement par Carte Bancaire :</b> Les Utilisateurs peuvent
            régler leur commande par carte bancaire directement via
            l'Application mobile/ Site internet. Le débit n’est effectué qu’au
            moment de la validation de la commande.
            <br />
            <b>b) Crédit Client :</b> Les Clients ont la possibilité de créditer
            un compte client via l'Application mobile Site internet. Ce crédit
            peut être utilisé pour régler les commandes ultérieures de Services.
            Le solde du compte client est automatiquement déduit à chaque
            commande. Les Utilisateurs peuvent recharger leur compte client à
            tout moment via l'application mobile.
          </p>
          <h6 className="text-dark margin-16">
            9.2. Sécurité des transactions
          </h6>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Afin d'optimiser la sécurité des transactions sur Internet, le Site
            utilise un système de paiement en ligne SSL (Secure Socket Layer) de
            telle sorte que tous les moyens sont mis en œuvre pour assurer la
            confidentialité et la sécurité des données transmises, dans le cadre
            d'un paiement en ligne. <br />
            Le système de paiement en ligne STRIPE contrôle automatiquement la
            validité des droits d'accès lors du paiement par carte bancaire et
            crypte tous les échanges afin d'en garantir la confidentialité.
            <br />
            Pour bénéficier du mode de paiement sécurisé SSL, le Client doit
            impérativement utiliser des navigateurs compatibles avec le système
            SSL.
          </p>
          <h4 className="text-dark margin-16">10. Livraison</h4>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Les frais de livraison sont à la charge exclusive du Client.
            <br />
            Ces frais sont forfaitaires et s’élèvent à 19,90€ HT pour toute
            Commande.
            <br />
            Les frais de livraison seront facturés au tarif en vigueur au jour
            de la commande.
            <br />
            La livraison est effectuée en France métropolitaine (hors Corse)
            uniquement.
            <br />
            Le Client prend connaissance des modalités de livraison avant
            validation définitive de sa commande et notamment du créneau horaire
            de livraison prévu.
            <br />
            Les risques de perte, vol ou détérioration des Produits sont
            transférés au Client à compter de la prise de possession des
            Produits par le Client, lors de la livraison.
            <h6>10.1. Forfait livraison</h6>
            <ul>
              <li>
                Ces frais de livraison incluent le transport et le déchargement
                au pied du camion à l'adresse mentionnée par le Client lors de
                la passation de sa commande.
              </li>
              <li>
                L'intégralité de la commande y compris les frais de livraison
                doit être payée avant la livraison.
              </li>
            </ul>
          </p>

          <h6 className="text-dark margin-16">10.2 Modalités de transport</h6>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            L'adresse de livraison doit se situer en France métropolitaine (hors
            Corse) uniquement et devra être accessible, notamment par une voie
            carrossable, sans danger ni risque pour le Vendeur. A ce titre, le
            Client devra signaler toute difficulté d'accès dans la zone
            commentaire de la commande lors du passage de sa commande. De plus,
            en cas de restriction de circulation, une dérogation doit être
            demandée en mairie par le client. <br />
            Notamment le Client doit s’assurer que sa rue soit bien autorisée au
            passage d’un véhicule. Le Vendeur se réserve le droit de refuser la
            livraison des Produits dans certaines zones difficiles d’accès. Le
            Vendeur décline toute responsabilité si un dommage quelconque
            survenait sur le lieu de livraison en raison d'un accès difficile ou
            d'un terrain non approprié. <br />
            Le Client doit assurer et prendre en charge sous sa responsabilité
            la direction des manœuvres nécessaires pour l'accès et la
            circulation sur le chantier. <br />
            Le Vendeur décline toute responsabilité si un dommage quelconque
            advenait sur un chantier par le transporteur, en raison d'un accès
            difficile ou d'un terrain non approprié. Le déchargement des
            marchandises est fait au sol devant le véhicule de livraison. Toute
            demande complémentaire fera l'objet d'un surcoût.
          </p>
          <h6 className="text-dark margin-16">
            10.3 Impossibilité de livraison
          </h6>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Dans l'hypothèse où la livraison à l'adresse indiquée par le Client
            ne serait pas possible en raison d’une erreur de saisie par le
            Client de son adresse de livraison : le Vendeur reprendra contact
            avec le Client pour redéfinir les modalités de mise à disposition de
            la commande. <br />
            Des frais de transport (incluant le retour et la nouvelle livraison)
            seront facturés au Client à hauteur de 2€ HT/ km et la nouvelle
            adresse de livraison devra se situer à moins de 30km de l’adresse
            initialement saisie lors de la Commande. <br /> Dans l’hypothèse où
            la livraison à l’adresse indiquée par le Client ne serait pas
            possible pour les raisons suivantes : inaccessibilité du lieu de
            livraison, absence du Client, refus par le Client de prendre la
            livraison sans motif, force majeure.... : la commande sera annulée
            et seul le prix de la Commande hors frais de de livraison sera
            remboursé au Client. Le Vendeur conservera les frais de livraison
            versés au moment de la Commande et facturera au Client des frais de
            retour des marchandises équivalents aux frais de livraison.
          </p>
          <h6 className="text-dark margin-16">10.4 Délais de livraison</h6>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Les délais de livraison sont indiqués sur le Site lors de la
            commande. Ils varient en fonction du mode de livraison choisi. Ils
            commencent à courir à compter de la réception du paiement par le
            Vendeur.
            <br />
            Bien qu'ils soient indicatifs, le Vendeur fera ses meilleurs efforts
            pour respecter les délais de livraison mentionnés lors de la
            commande.
            <br />
            Le Vendeur informera le Client par tout moyen de communication de
            tout retard éventuel de livraison dont elle aura connaissance.
            <br />
            Pour les clients professionnels les retards de livraison ne peuvent
            donner lieu à aucune pénalité ou indemnité, ni motiver l'annulation
            de la commande.
            <br />
            En tout état de cause, le Vendeur ne saurait être tenu responsable
            des conséquences liées à un retard ou à une suspension de livraison
            dû à un cas de force majeure tel que défini à l'article « Force
            majeure » des Présentes CGV ou d'événements indépendants de la
            volonté du Vendeur ou de celle de ses fournisseurs ou résultant de
            la faute ou de l'erreur du Client dans la fourniture des
            informations requises.
          </p>
          <h4 className="text-dark margin-16">11. Réception des Produits</h4>
          <p className="bleu800 subtitle-m mt-16 margin-32">
            Lors de la réception de la commande et avant le départ du livreur le
            Client doit procéder à l'ouverture et à la vérification du contenu
            du (des) colis livré(s), tant en ce qui concerne la conformité
            apparente que la quantité commandée. Le Client signera à cet effet
            un bon de livraison. En cas d'anomalie apparente (et notamment :
            emballage abîmé, Produits manquants, abîmés ou cassés), il
            appartiendra au Client de :
            <ul>
              <li>
                décrire de manière précise sur le bon de livraison l'état des
                Produits (exemples : « miroir brisé », « erreur de coloris », «
                erreur de dimension »...),
              </li>
              <li>faire co-signer les éventuelles réserves par le livreur,</li>
              <li>
                dans les 3 jours suivant la livraison, en informer le Vendeur en
                le contactant soit via son site, soit via le Service Clients à
                l’adresse : contact@marion-materiaux.fr
              </li>
              <li>
                en précisant son nom, prénom et adresse ainsi que le numéro de
                la commande concernée.
              </li>
            </ul>
            Le Client devra fournir toute justification quant à la réalité des
            non-conformités, avaries ou défauts constatés. À défaut de
            réclamation effectuée dans le délai et conditions précités, les
            Produits livrés seront réputés acceptés par le Client sans que cela
            ne fasse obstacle à l'application ultérieure le cas échéant des
            garanties conventionnelles ou légales.
            <br />
            S’il justifie du vice ou de la non-conformité du Produit, le Client
            devra spécifier s’il sollicite une mise en conformité du Produit par
            réparation ou remplacement ou à défaut une résolution de la
            Commande. Le Vendeur peut ne pas procéder selon le choix opéré par
            le Client si la mise en conformité est impossible ou entraîne des
            coûts disproportionnés. Une autorisation de retour sera émise par le
            Vendeur, précisant l'adresse et les modalités de retour des
            Produits.
            <br />
            Une fois l'autorisation de retour obtenue, le Client réexpédie les
            Produits au Distributeur dans un délai maximum de HUIT (8) jours à
            l'adresse précisée sur l'autorisation de retour selon les modalités
            indiquées. Le Client devra justifier au vendeur la bonne réception
            (contre signature) des Produits réexpédiés.
            <br />
            Tout Produit réexpédié sans l'accord du Vendeur sera refusé et
            retourné à l'expéditeur aux frais et risques de ce dernier.
            <br />
            Pour être valablement retournées au Vendeur et/ou échangés, les
            Produits ne doivent pas avoir été utilisés et doivent être retournés
            intactes dans leur emballage d'origine avec leurs éventuels
            accessoires, manuels d'utilisation et autre documentation.
            <br />
            Dans le cas où le Client retournerait le Produit pour un défaut de
            conformité avéré et confirmé par le Vendeur, le Client devra
            utiliser pour le retour des Produits le même mode de livraison que
            celui choisi pour l'expédition des Produits commandés. Dans ce
            cadre, les frais de retour seront pris en charge par le Vendeur au
            moyen d'un bon de retour.
          </p>
          <h4 className="text-dark margin-16">12. Garantie</h4>
          <p className="bleu800 subtitle-m mt-16 ">
            <b>
              Garantie de conformité et garantie légale contre les vices cachés
            </b>
            <br />
            Le Client consommateur bénéficie de la garantie légale de conformité
            (articles L. 217-3 à L. 217-12 du code de la consommation) et de la
            garantie légale relative aux défauts de la chose vendue (articles
            1641 à 1648 du code civil ).
            <br />
            <b>GARANTIE DE CONFORMITÉ</b> <br />
            Extraits du Code de la consommation et du code civil :<br />
            <b>Article L 217-3 du code de la consommation</b> <br />
          </p>
          <p className="bleu800 subtitle-m mt-16">
            Le vendeur livre un bien conforme au contrat ainsi qu’aux critères
            énoncés à l’article L217-5. Il répond des défauts de conformité
            existant au moment de la délivrance du bien au sens de l’article
            216-1, qui apparaissent dans le délai de deux ans à compter de
            celle-ci. <br />
            Le vendeur répond également, durant les mêmes délais, des défauts de
            conformité résultant de l'emballage, des instructions de montage ou
            de l'installation lorsque celle-ci a été mise à sa charge par le
            contrat ou a été réalisée sous sa responsabilité, ou encore lorsque
            l’installation incorrecte effectuée par le consommateur comme prévu
            au contrat, est due à des lacunes ou erreurs dans les instructions
            d’installation fournies par le vendeur.
            <br />
            Ce délai de garantie s’applique sans préjudice des articles 2224 et
            suivants du code civil. Le point de départ de la prescription de
            l’action du consommateur est le jour de la connaissance par ce
            dernier du défaut de conformité.
          </p>

          <p className="bleu800 subtitle-m mt-16">
            <b>Article L 217-4 du code de la consommation :</b>
            <br />
            Le bien est conforme au contrat s'il répond notamment, le cas
            échéant, aux critères suivants : 1° Il correspond à la description,
            au type, à la quantité et à la qualité, notamment en ce qui concerne
            la fonctionnalité, la compatibilité, l'interopérabilité, ou toute
            autre caractéristique prévues au contrat ; <br />
            2° Il est propre à tout usage spécial recherché par le consommateur,
            porté à la connaissance du vendeur au plus tard au moment de la
            conclusion du contrat et que ce dernier a accepté ; 3° Il est
            délivré avec tous les accessoires et les instructions
            d'installation, devant être fournis conformément au contrat ; <br />
            4° Il est mis à jour conformément au contrat.
          </p>

          <p className="bleu800 subtitle-m mt-16">
            <b>Article 217-7 du code de la consommation</b>
            <br />
            Les défauts de conformité qui apparaissent dans un délai de
            vingt-quatre mois à compter de la délivrance du bien, y compris du
            bien comportant des éléments numériques, sont, sauf preuve
            contraire, présumés exister au moment de la délivrance, à moins que
            cette présomption ne soit incompatible avec la nature du bien ou du
            défaut invoqué.
          </p>

          <p className="bleu800 subtitle-m mt-16">
            <b>Article L 217-8 alinéa 1er du code de la consommation :</b>
            <br />
            En cas de défaut de conformité, le consommateur a droit à la mise en
            conformité du bien par réparation ou remplacement ou, à défaut, à la
            réduction du prix ou à la résolution du contrat, dans les conditions
            énoncées à la présente sous-section.
          </p>

          <p className="bleu800 subtitle-m mt-16">
            <b>Article L 217-10 du code de la consommation :</b>
            <br />
            La mise en conformité du bien a lieu dans un délai raisonnable qui
            ne peut être supérieur à trente jours suivant la demande du
            consommateur et sans inconvénient majeur pour lui, compte tenu de la
            nature du bien et de l'usage recherché par le consommateur.
            <br />
            La réparation ou le remplacement du bien non conforme inclut, s'il y
            a lieu, l'enlèvement et la reprise de ce bien et l'installation du
            bien réparé ou du bien de remplacement par le vendeur.
            <br />
            Un décret précise les modalités de la mise en conformité du bien
          </p>

          <p className="bleu800 subtitle-m mt-16">
            <b>Article L 217-12 du code de la consommation :</b>
            <br />
            Le vendeur peut ne pas procéder selon le choix opéré par le
            consommateur si la mise en conformité sollicitée est impossible ou
            entraîne des coûts disproportionnés au regard notamment : <br />
            1° De la valeur qu'aurait le bien en l'absence de défaut de
            conformité ; <br />
            2° De l'importance du défaut de conformité ; et <br />
            3° De la possibilité éventuelle d'opter pour l'autre choix sans
            inconvénient majeur pour le consommateur. <br />
            Le vendeur peut refuser la mise en conformité du bien si celle-ci
            est impossible ou entraîne des coûts disproportionnés notamment au
            regard des 1° et 2°. <br />
            Lorsque ces conditions ne sont pas respectées, le consommateur peut,
            après mise en demeure, poursuivre l'exécution forcée en nature de la
            solution initialement sollicitée, conformément aux articles 1221 et
            suivants du code civil. <br />
            Tout refus par le vendeur de procéder selon le choix du consommateur
            ou de mettre le bien en conformité, est motivé par écrit ou sur
            support durable.
          </p>

          <p className="bleu800 subtitle-m mt-16">
            <b>GARANTIE CONTRE LES VICES CACHÉS</b> <br />
            <u>Article 1641 du code civil :</u> <br />
            Le vendeur est tenu de la garantie à raison des défauts cachés de la
            chose vendue qui la rendent impropre à l'usage auquel on la destine,
            ou qui diminuent tellement cet usage que l'acheteur ne l'aurait pas
            acquise, ou n'en aurait donné qu'un moindre prix, s'il les avait
            connus.
          </p>

          <p className="bleu800 subtitle-m mt-16 margin-32">
            <u>Article 1648 alinéa premier du code civil :</u>
            <br />
            L'action résultant des vices rédhibitoires doit être intentée par
            l'acquéreur dans un délai de deux ans à compter de la découverte du
            vice.
          </p>
          <h4 className="text-dark margin-16">
            13. Responsabilité - Informations
          </h4>
          <p className="bleu800 subtitle-m mt-16 margin-16">
            Le Vendeur s'efforce d'assurer au mieux de ses possibilités
            l'exactitude et la mise à jour des informations diffusées sur le
            Service qu'il se réserve le droit de modifier, à tout moment et sans
            préavis. Toutefois, il ne peut garantir l'exactitude, la précision
            ou l'exhaustivité des informations figurant sur le Service. En
            conséquence, il décline toute responsabilité quant aux imprécisions,
            inexactitudes ou omissions portant sur les informations disponibles,
            ainsi que toute responsabilité relative aux dommages résultant d'une
            intrusion frauduleuse d'un tiers ayant entraîné une modification des
            informations contenues sur le Service.
            <br />
            Les liens hypertextes mis en place dans le cadre du présent Site
            Internet en direction d'autres ressources présentes sur le réseau
            Internet ne sauraient engager la responsabilité du Vendeur. En
            effet, le Vendeur ne dispose d'aucun moyen pour contrôler les Sites
            en connexion avec son Site, ce dont le Client est expressément
            informé en vertu des présentes. En conséquence, les risques liés à
            cette utilisation incombent pleinement à l'internaute, qui doit se
            conformer aux conditions d'utilisation spécifiques à chaque Site.
            <br />
            De la même manière, les photographies et les textes reproduits et
            illustrant les Produits présentés ne sont pas contractuels. En
            conséquence, la responsabilité du Vendeur ne saurait être engagée en
            cas d'erreur dans l'une de ces photographies ou l'un de ces textes.
          </p>

          <p className="bleu800 subtitle-m mt-16">
            <b>Conformité des Produits aux normes applicables</b>
            <br />
            Le Vendeur s'engage à vendre des Produits conformes à la
            réglementation et aux normes en vigueur dans l'Union Européenne au
            moment de la vente.
          </p>

          <p className="bleu800 subtitle-m mt-16">
            <b>Utilisation/installation non-conforme du Produit</b>
            <br />
            Le Vendeur décline toute responsabilité et ne pourra être tenu pour
            responsable de tous dommages causés au Client ou à un tiers dus à
            une utilisation du Produit non-conforme à sa destination.
          </p>
          <h4 className="text-dark margin-32">14. Droit de rétractation</h4>
          <p className="bleu800 subtitle-m mt-16 margin-16">
            Conformément à l'article L. 221-18 du code de la consommation, le
            consommateur qui conclut un contrat par le biais d'un moyen de
            communication à distance dispose d'un délai de rétractation de
            quatorze (14) jours à compter de la date de réception du Produit
            pour revenir sur sa commande.
            <br />
            Il est précisé que dans le cas d'une commande portant sur plusieurs
            biens livrés séparément ou dans le cas d'une commande d'un bien
            composé de lots ou de pièces multiples dont la livraison est
            échelonnée sur une période définie, le délai court à compter de la
            réception du dernier bien ou lot ou de la dernière pièce.
            <br />
            Pour exercer son droit de rétractation, le Client dispose de
            quatorze (14) jours pour informer le vendeur de son intention de se
            rétracter en remplissant et en envoyant le formulaire de
            rétractation tenu à sa demande au contact@marion-materiaux.fr
            <br />
            Lorsque le droit de rétractation est transmis électroniquement en
            ligne à partir du Service, le Vendeur adressera sans délai au Client
            un accusé de réception de la rétractation mentionnant l’adresse à
            laquelle le Client devra retourner les Produits.
            <br />
            Par la suite le Client envoie les Produit à l’adresse indiquée dans
            un délai de quatorze (14) jours à compter de l'envoi du formulaire
            de rétractation, accompagnés de la copie de la facture. Il est
            précisé au Client que les frais de retour des Produits en cas de
            rétractation sont à sa charge exclusive. Ces frais de retour du
            Produit seront équivalents aux frais de livraison initiaux en vertu
            du mode de livraison standard proposé par le Vendeur lors de la
            commande.
            <br />
            Le Vendeur s'engage à rembourser au Client le prix de sa commande
            dans les 14 jours de la réception des Produits ou tout au moins de
            la preuve de l'expédition des Produits (première des deux dates).
            Les Produits ne doivent pas avoir été utilisés ou endommagés et
            doivent être retournés intacts dans leur emballage d'origine avec
            leurs éventuels accessoires, manuels d'utilisation et autre
            documentation. La responsabilité du Client pourra être engagée en
            cas de détérioration du Produit par le Client.
          </p>
          <h4 className="text-dark margin-32">18. Force majeure</h4>
          <p className="bleu800 subtitle-m mt-16 margin-16">
            Le Vendeur dégage sa responsabilité pour tout manquement à ses
            obligations contractuelles dans l'hypothèse d'une force majeure ou
            fortuite, y compris, mais sans y être limitées, catastrophes,
            incendies, pandémie, grève interne ou externe, défaillance ou pannes
            internes ou externes, et d'une manière générale tout événement ne
            permettant pas la bonne exécution des commandes et des livraisons.
          </p>
          <h4 className="text-dark margin-32">
            19. Loi applicable et juridiction compétente
          </h4>
          <p className="bleu800 subtitle-m mt-16 margin-16">
            Les présentes Conditions Générales sont soumises au droit interne
            français, exclusion faite de toute convention internationale.
            <br />
            En cas de litige relatif aux présentes Conditions Générales ou à la
            vente, les parties essaieront dans la mesure du possible de résoudre
            leur litige à l'amiable.
            <br />
            À défaut d'accord amiable, lorsque le Client est un consommateur, le
            Tribunal compétent est celui du lieu du domicile du défendeur ou
            celui de la livraison effective du Produit.
            <br />
            Lorsque le client agit en tant que professionnel, compétence
            expresse est attribuée aux tribunaux de Nice, nonobstant pluralité
            de défendeurs et/ou appel en garantie, même pour les procédures
            d'urgence ou les procédures conservatoires en référé ou par requête.
          </p>
          <h4 className="text-dark margin-32">
            20. Recours à la médiation de la consommation
          </h4>
          <p className="bleu800 subtitle-m mt-16 margin-16">
            Si le Client est un « consommateur » au sens de l'article
            préliminaire du Code de la consommation, il doit en premier lieu
            adresser au Vendeur sa réclamation directement par le biais du
            formulaire de contact du Site en sélectionnant l'objet « Réclamation
            » ou à l’adresse suivante : contact@marion-materiaux.fr Si cette
            tentative échoue, il pourra recourir à une procédure de médiation de
            la consommation ou à tout autre mode alternatif de règlement des
            différends et notamment en ayant recours, gratuitement, dans le
            délai d'un an à compter de la réclamation, au médiateur de la
            consommation suivant :<br />
            Médiateur de la Fédération professionnelle du e-commerce et de la
            vente à distance (FEVAD) <br /> 60 rue de la Boétie – 75008 PARIS
            <br /> mediateurducommerce@fevad.com
            <br />
            https://www.mediateurfevad.fr <br /> Le client consommateur pourra,
            afin de résoudre son litige, accéder à la plateforme européenne de
            règlement des litiges en ligne prévu par le règlement (UE) no
            524/2013 du 21 mai 2013 relatif au règlement en ligne des litiges de
            consommation, notamment transfrontaliers, en suivant le lien
            http://ec.europa.eu/ consumers/odr/. <br /> En cas d'échec de cette
            médiation, ou si le client consommateur ne souhaite pas y recourir,
            il demeura libre de soumettre son différend aux tribunaux
            compétents.
          </p>
        </div>
      </div>
    </LayoutF>
  );
};
export default Cgv;

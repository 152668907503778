import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DateRangePicker, DateRange } from "react-date-range";
import { fr } from "date-fns/locale";
import "react-calendar/dist/Calendar.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Services from "../services/global.service";
import Arrow from "../assets/imgs/keyboard_arrow_down_24px.svg";
import Check from "../assets/imgs/fi_check.svg";

const FiltreOrders = ({ setEndPoint, endPoint }) => {
  const [value, onChange] = useState();
  const [myAddress, setMyAddress] = useState();
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [statusSelect, setStatusSelect] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);

  const status = [
    { key: "1", value: "En cours de livraison" },
    { key: "2", value: "Livré" },
    { key: "3", value: "En cours de préparation" },
    { key: "4", value: "En cours de validation" },
    { key: "5", value: "Validé" },
    { key: "6", value: "Annulé" },
  ];
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const formatDate = (date) => {
    return new Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };
  const handleOnChange = (ranges) => {
    console.log("range detected", ranges);
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
    if (selection.startDate && selection.endDate) {
      updateEndpoint(selection);
    }
  };

  const updateEndpoint = (selection) => {
    let newEndpoint = endPoint;
    const startDate = selection?.startDate?.toISOString()?.split("T")[0];
    const endDate = selection?.endDate?.toISOString()?.split("T")[0];
    const dateRangeParam = `date=${startDate},${endDate}`;
    const dateParamIndex = newEndpoint.indexOf("date=");

    if (dateParamIndex !== -1) {
      const ampersandIndex = newEndpoint.indexOf("&", dateParamIndex + 1);
      const endOfDateParam =
        ampersandIndex !== -1 ? ampersandIndex : newEndpoint.length;
      newEndpoint =
        newEndpoint.slice(0, dateParamIndex) +
        dateRangeParam +
        newEndpoint.slice(endOfDateParam);
    } else {
      newEndpoint += `&${dateRangeParam}`;
    }

    setEndPoint(newEndpoint);
  };

  function formatStatus(status) {
    switch (status) {
      case "4":
        return "En cours de validation";
      case "1":
        return "En cours de livraison";
      case "3":
        return "En cours de préparation";
      case "2":
        return "Livré";
      case "5":
        return "Validé";
      case "6":
        return "Annulé";
      default:
        return status;
    }
  }
  const handleAddressSelect = (addressId, addressName) => {
    const addressIndex = selectedAddress.findIndex(
      (address) => address.key === addressId
    );

    if (addressIndex !== -1) {
      setSelectedAddress((prevAddresses) =>
        prevAddresses.filter((address) => address.key !== addressId)
      );
    } else {
      setSelectedAddress((prevAddresses) => [
        ...prevAddresses,
        { key: addressId, value: addressName },
      ]);
    }
  };
  const getMyAdresses = () => {
    Services.getOne(localStorage.getItem("id"), "adresses")
      .then((res) => {
        setMyAddress(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStatusSelect = (value) => {
    if (statusSelect.includes(value)) {
      setStatusSelect(statusSelect.filter((item) => item !== value));
    } else {
      setStatusSelect([...statusSelect, value]);
    }
  };


  const handleValider = () => {
    let newEndpoint = "commandes/filter?";
    let params = [];
  
    if (statusSelect.length > 0) {
      params.push(`status=${statusSelect.join(",")}`);
    }
  
    if (selectedAddress.length > 0) {
      const addressIds = selectedAddress.map((address) => address.key);
      params.push(`adresse_id=${addressIds.join(",")}`);
    }
  
    newEndpoint += params.join("&");
  
    setEndPoint(newEndpoint);
    setOpenDropdown(null);
  };
  
  const resetFilters = () => {
    
    setStatusSelect([]);
    setSelectedAddress([]);
    setEndPoint('commandes/filter?')
  };

  const handleReset = (option) => {
    if (option === "status") {
      setStatusSelect([]);
    }
    if (option === "adresse") {
      setSelectedAddress([]);
    }
    setEndPoint('commandes/filter?')

  };

  const handleDeleteOption = (option, value) => {
    if (option === "status") {
      setStatusSelect(statusSelect.filter((el) => el !== value));

    }
    if (option === "adr") {
      setSelectedAddress(selectedAddress.filter((el) => el.key !== value));
    }
    if (option === "date") {
      onChange(null);
    }
  };
  useEffect(() => {
    handleValider();
  }, [statusSelect, selectedAddress]);
  useEffect(() => {
    getMyAdresses();
  }, []);

  return (
    <>
      <div className="row order-filtre ">
        <div className="col-auto">
          <Dropdown
            autoClose={false}
            show={openDropdown === "date"}
            onToggle={(isOpen) =>
              setOpenDropdown(isOpen ? "date" : null)
            }
          >
            <Dropdown.Toggle
              className="select-filtre body-s d-flex"
              id="dateDropdown"
            >
              <span className="body-s">Date</span>
              {value && <span className="selectedCount">1</span>}
              <img
                src={Arrow}
                alt="arrow icon"
                className={`arrow-icon ${openDropdown === "date" ? "open" : "closed"}`}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="select-options">
              <Dropdown.Item className="body-s">
                <DateRange
                  onChange={handleOnChange}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                  direction="horizontal"
                  locale={fr}
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-auto">
          <Dropdown
            autoClose={false}
            show={openDropdown === "address"}
            onToggle={(isOpen) =>
              setOpenDropdown(isOpen ? "address" : null)
            }
          >
            <Dropdown.Toggle
              className="select-filtre d-flex body-s"
              id="adrDropdown"
            >
              <span className="body-s">Adresse de livraison</span>
              {selectedAddress.length>0 && <span className="selectedCount">{selectedAddress.length}</span>}
              <img
                src={Arrow}
                alt="arrow icon"
                className={`arrow-icon ${openDropdown === "address" ? "open" : "closed"}`}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="select-options">
              {myAddress?.map((val, subIndex) => (
                <Dropdown.Item
                  className="Exergue bleu700"
                  key={subIndex}
                  onClick={() => handleAddressSelect(val.id, val.adresse)}
                >
                  {val?.adresse}
                  {selectedAddress?.key === val?.id && (
                    <img src={Check} alt="check icon" className="text-end" />
                  )}
                </Dropdown.Item>
              ))}
              {selectedAddress ? (
                <div className="dropdown-buttons">
                  <button
                    className="cta-primary-m w-100 mt-24"
                    onClick={() => handleValider()}
                  >
                    Valider
                  </button>
                  <button
                    className="cta-secondary-m mt-8 w-100"
                    onClick={() => handleReset("adresse")}
                  >
                    Rénitialiser
                  </button>
                </div>
              ) : (
                <div className="dropdown-buttons">
                  <button
                    className="cta-primary-m w-100 mt-24"
                    onClick={() => setOpenDropdown(null)}
                  >
                    Fermer
                  </button>
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-auto">
          <Dropdown
            autoClose={false}
            show={openDropdown === "status"}
            onToggle={(isOpen) =>
              setOpenDropdown(isOpen ? "status" : null)
            }
          >
            <Dropdown.Toggle
              className="select-filtre body-s d-flex"
              id="sortingDropdownSort"
            >
              <span className="body-s">Statut</span>
              {statusSelect?.length > 0 && (
                <span className="selectedCount">{statusSelect?.length}</span>
              )}
              <img
                src={Arrow}
                alt="arrow icon"
                className={`arrow-icon ${openDropdown === "status" ? "open" : "closed"}`}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="select-options">
              {status?.map((val, subIndex) => (
                <Dropdown.Item
                  className="Exergue bleu700 d-flex justify-content-between"
                  onClick={() => handleStatusSelect(val?.key)}
                  key={subIndex}
                >
                  {val?.value}
                  {statusSelect?.includes(val.key) && (
                    <img src={Check} alt="check icon" className="text-end" />
                  )}
                </Dropdown.Item>
              ))}
              {statusSelect?.length > 0 ? (
                <div className="dropdown-buttons">
                  <button
                    className="cta-primary-m w-100 mt-24"
                    onClick={() => handleValider()}
                  >
                    Valider
                  </button>
                  <button
                    className="cta-secondary-m mt-8 w-100"
                    onClick={() => handleReset("status")}
                  >
                    Rénitialiser
                  </button>
                </div>
              ) : (
                <div className="dropdown-buttons">
                  <button
                    className="cta-primary-m w-100 mt-24"
                    onClick={() => setOpenDropdown(null)}
                  >
                    Fermer
                  </button>
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {(statusSelect.length > 0 || selectedAddress.length>0 || value) && (
        <>
          <div className="row mt-16 align-items-center">
            {statusSelect.map((value) => (
              <div className="col-auto selectedFilter mr-16" key={value}>
                <span>
                  {formatStatus(value)}
                  <button
                    className="link-s"
                    onClick={() => handleDeleteOption("status", value)}
                  >
                    x
                  </button>
                </span>
              </div>
            ))}
         {selectedAddress.map((address) => (
              <div className="col-auto selectedFilter mr-16" key={address.key}>
                <span>
                  {address.value}
                  <button
                    className="link-s"
                    onClick={() => handleDeleteOption("adr", address.key)}
                  >
                    x
                  </button>
                </span>
              </div>
            ))}
          
            {value  && (
              <div className="col-auto selectedFilter mr-16" key={value}>
                <span>
                
                {formatDate(value.startDate)} - {formatDate(value.endDate)}
                  <button
                    className="link-s"
                    onClick={() => handleDeleteOption("date", null)}
                  >
                    x
                  </button>
                </span>
              </div>
            )}
            {(statusSelect.length > 0 || selectedAddress) && (
              <div className="col-auto">
                <button className="link-s" onClick={resetFilters}>
                  Tout effacer
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FiltreOrders;

import { Modal, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
  
} from 'react-places-autocomplete';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import Position from '../assets/imgs/fi_navigation.svg';
import Services from '../services/global.service';
import '../assets/css/modal.scss'
import Default from '../assets/imgs/fi_check.svg'
import Map from '../assets/imgs/fi_map-pin-orange.svg'
const ModalAdr = ({cart, modalAdr, setModalAdr ,setAccessibleModal,accessibleModal,check,setCheck}) => {
  const [address, setAddress] = useState('');
 

  const [myAddress, setMyAddress] = useState([]);
  const [latLong, setLatLong] = useState(null); 

  const handleChangeAddress = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    setAddress(address);
    console.log('adresse',address)
    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;

      // Extract necessary address components
      const postalCode = addressComponents.find(component => 
        component.types.includes('postal_code')
      );
      const street = addressComponents.find(component => 
        component.types.includes('route')
      );
      if (postalCode && street) {
        console.log('Address is complete:', postalCode.long_name, street.long_name);
  
        const latLng = await getLatLng(results[0]);
        console.log(latLng);
        if (latLng) {
          setLatLong(latLng);
          checkAdr(latLng.lat, latLng.lng);
        } else {
          console.error('Error: Unable to get latitude and longitude');
        }
      } else {
        console.error('Error: Incomplete address. Missing postal code or street.');
      }
  
      // const latLng = await getLatLng(results[0]);
      // console.log(latLng)
      // if (latLng) {
      //   setLatLong(latLng);
      //   checkAdr(latLng.lat, latLng.lng);
      // } else {
      //   console.error('Error: Unable to get latitude and longitude');
      // }
    } catch (error) {
      console.error('Error:', error);
    }
  };
const getMyAdresses=()=>{
    Services.getOne(localStorage.getItem('id'),'adresses')
        .then((res)=>{
          const filteredAddresses = res.data.filter(adr => adr.type === 1);
          // console.log('vvvvvv',filteredAddresses.filter(adr=>adr.default===1)[0]);
          setMyAddress(filteredAddresses);
          const defaultAdr=filteredAddresses.filter(adr=>adr.default===1)[0]
          setAddress(defaultAdr.adresse)
          setLatLong({
            latitude: defaultAdr.latitude,
            longitude: defaultAdr.longitude
          });
        })
        .catch((err)=>{console.log(err)})
}
  const getMyPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
       async (position) => {
          console.log(position);
          // Successfully retrieved the position
          setLatLong({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
          const results = await geocodeByLatLng({ lat:position.coords.latitude , lng: position.coords.longitude });
          const address = results[0].formatted_address;
          console.log('formted adr',address)
          setAddress(address);
           setAddress(address);
          checkAdr()
        },
        (error) => {
          // Error occurred while retrieving the position
          console.error('Error getting geolocation:', error);
        }
      );
    } else {
      // Geolocation is not supported by the browser
      console.error('Geolocation is not supported');
    }
  };

  const handleClose = () => {
    setModalAdr(false);
  };
  const createAdr=()=>{
    console.log(latLong)
    const adr={
      name:'Mon adresse',
      nom:localStorage.getItem('nom') ?? '',
      prenom:localStorage.getItem('prenom') ?? '',
      latitude:latLong.lat,
      longitude:latLong.lng,
      adresse:address,
      user:localStorage.getItem('id'),
      type:1,
      default:1
    }
    Services.create(adr,'adresses/create')
    .then((res)=>{
      console.log(res.data)
      changeDefault(res?.data?.adresse?.id)
    })
    .catch((err)=>{console.log(err)})
  }
  const checkAdr=(lat,lng)=>{
    console.log(lat,lng)
    const data= {
      latitude:lat,
      longitude:lng
    }
    console.log('data', data)
    Services.create(data,'zones/adresse')
    .then((res)=>{
        console.log('check',res);
        setCheck(res.data)
        //add adresse set localstorage adr
        })
    .catch((err)=>{console.log(err)})
  }
  const validateAdr=()=>{
    if(check){
       localStorage.setItem('adr',address)
       localStorage.setItem('lat',latLong.lat)
       localStorage.setItem('lng',latLong.lng)
       //add adresse de liv par defaut 1
       if(latLong){
            createAdr()
       }
   
    }
   
    setAccessibleModal(true); 
    console.log(accessibleModal)
    setModalAdr(!modalAdr)
    //open modal zone accesible or not , we can pass props with type of accessibility tri=ue or false to the modal

  }
  const isAddressWithPostalCodeAndStreet = (suggestion) => { const { mainText, secondaryText } = suggestion.structured_formatting; const postalCodePattern = /\b\d{5}\b/; const hasStreet = /,/.test(mainText) || /,/.test(secondaryText); return postalCodePattern.test(mainText) || postalCodePattern.test(secondaryText) && hasStreet; };
  const changeDefault=(idAdr)=>{
    Services.getAll(`addresses/${localStorage.getItem('id')}/update-default-livraison-address/${idAdr}`)
    .then((res)=>{
      console.log(res)
      getMyAdresses()
    })
    .catch((err)=>{console.log('err',err)})
  }
  const searchOptions = {
    componentRestrictions: { country: ['fr'] },
    types: ['address']
  }
useEffect(()=>{getMyAdresses()},[])
  return (
  <>
 
    <Modal show={modalAdr} onHide={handleClose} className='modal-marion'>
      <Modal.Header closeButton>
        <h6>À quelle adresse souhaites-tu te faire livrer ?</h6>
      </Modal.Header>
      <Modal.Body>
        <Autocomplete
          value={address}
          className=""
          searchOptions={searchOptions}
          onChange={handleChangeAddress}
          onSelect={handleSelect}
          onPlaceSelected={(place) => {
            //console.log(place);
          }}
      
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Saisir ton adresse de livraison',
                  className: ' adresse-marion search-adr '
                })}
              />
              <div className={suggestions.length > 0 && `container-autocomplete`}>
                {loading ? <div>Loading...</div> : null}
                {suggestions
                .map((suggestion) => {
                
                  const style = {
                    backgroundColor: suggestion.active ? '#41B6E6' : '#fff'
                  };
                  return (
                    <div
                    className='mapItem'
                      {...getSuggestionItemProps(suggestion, {
                        style
                      })}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Autocomplete>
        <p onClick={getMyPosition} className='mt-18 link-cursor'>
          <img src={Position} alt="icon position" className='mr-8'/> <span className="link-s">Utiliser ma position actuelle</span> 
        </p>
        {cart ?
        <>
        <div className="error-adr">
            <p className="m-0">Attention, si tu modifies ton adresse de livraison et 
                que celle-ci est située à une distance trop importante, les articles actuellement 
                dans ton panier pourraient être perdus. N'oublie pas de 
                les sauvegarder dans tes favoris avant d'apporter des changements.</p>
        </div>
        </> :''}
        
        {myAddress.length>0 &&  
        <> <p className="subtitle-m pt-24 bleu800 border-top-marion">Adresse récemment utilisées</p>
         <div>{myAddress.map((adr,index)=>(
        <>  <div className={`d-flex justify-content-between mt-16 ${index > 0 ? ' border-top-marion' : ''}`} onClick={() => changeDefault(adr.id)}> <span className="rec-adr bleu800">
            <img src={Map} alt='map icon'className='mr-8'/>
            {adr.adresse} 
           
           </span>
           {(adr.default===1 && adr.type===1) && <img src={Default} className='text-end' alt="check icon"/>}
            
           </div>
           </>
        ))}</div>
         </>
        }
       
                <div className="text-center mt-24">
                     <button className="cta-primary-m" onClick={validateAdr} disabled={latLong==null}>Valider</button>
                </div>
       
      </Modal.Body>
     
    </Modal>
    </>
  );
};

export default ModalAdr;

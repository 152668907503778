import React from 'react'
import LayoutF from '../components/LayoutF';
import validationCart from '../assets/imgs/validationCart.svg'
import { Link } from 'react-router-dom';
const ValidationOrder=()=> {
    return (
        <LayoutF>
          <div className="bg-auth pt-40 ">
            <div className=" container d-flex align-items-center">
              <div className="row w-50 mx-auto justify-content-center align-items-center">
                <img src={validationCart} alt="order validate" className='w-auto mt-40'/>
                <div className="row justify-content-center align-items-center text-start mb-60">
                    
                  <h5>Merci, ta commande a bien été prise en compte !</h5>
                  <p className="body-m bleu500 mt-8">Elle est en cours de traitement et sera validé par un de nos conseillers. Tu recevras un email de confirmation rapidement</p>
                 
                    <Link className="cta-primary-m mt-42 w-auto " to="/profil/4">Voir le détail de ma commande</Link>
               
                </div>
                
              </div>
            </div>
          </div>
        </LayoutF>
      );
}

export default ValidationOrder
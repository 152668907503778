import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Services from "../services/global.service";
import AddCardEelement from "./addCardElement";
const pk=process.env.REACT_APP_STRIPE_PK
const stripePromise = loadStripe(pk);

const AddCard = ({ addModal, setAddModal,clientSecret,setupIntent }) => {


  const handleClose = () => {
    setAddModal(false);
  };

  
  const options = {
    clientSecret: setupIntent.setupIntent,
  };
  const handleCardAdded = () => {
    // Close the pop-up after the card is added
    setAddModal(false);
  };
  

  return (
    <Modal show={addModal} onHide={handleClose} className="modal-marion">
      <Modal.Header closeButton>
        <h6>Ajouter Carte bancaire</h6>
      </Modal.Header>
      <Modal.Body>
        
        {clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <AddCardEelement setupIntent={setupIntent} handleCardAdded={handleCardAdded} />
          </Elements>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddCard;

import React, { useState } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import ErrorIcon from '../assets/imgs/fi_alert-triangle.svg'
import EyeClosed from "../assets/imgs/fi_eye-off.svg";
import Eye from "../assets/imgs/fi_eye.svg";
import Info from '../assets/imgs/fi_info.svg'
import AuthService from "../services/auth.service";

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Ancien mot de passe est requis"),
  newPassword: Yup.string()
    .required("Nouveau mot de passe est requis")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/,
      "Le mot de passe doit contenir au moins 8 caractères, une majuscule et un caractère spécial."
    ),
});

const ChangePasswordConnected = () => {
  const initialValues = {
    oldPassword: "",
    newPassword: "",
  };

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleTogglePasswordVisibility = (field) => {
    switch (field) {
      case "oldPassword":
        setShowOldPassword((prevShow) => !prevShow);
        break;
      case "newPassword":
        setShowNewPassword((prevShow) => !prevShow);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (values, { resetForm }) => {
  
 
    AuthService.PasswordChange(values)
      .then((res) => {
        if (res.success) {
         toast.success('Mot de passe changé avec succès!', {
           position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
           pauseOnHover: true,
          draggable: true,
         });
        } else {
          console.error("Error changing password:");
          toast.error('Échec de la modification du mot de passe. Veuillez réessayer.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        console.error("Error changing password:", err);
        toast.error('Échec de la modification du mot de passe. Veuillez réessayer', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChangePasswordSchema}
      onSubmit={handleSubmit}
    >
      {({ values,errors,touched, handleChange }) => (
        <Form className="profil min-vh-80 col-md-6 mx-auto mt-24">
          <h5>Changer mon mot de passe</h5>
          <div className="col-12">
            <div className="password-input-container form-floating mb-3 flex-grow-1 mt-16">
              <input
                type={showOldPassword ? "text" : "password"}
                name="oldPassword"
                value={values.oldPassword}
                onChange={handleChange}
                placeholder=""
                className={`form-control ${touched.oldPassword && errors.oldPassword ? "danger-input" : ""}`}
              />
              <label htmlFor="password">Saisis ton mot de passe actuel</label>
              {showOldPassword ? (
                <img
                  src={EyeClosed}
                  className="password-icon"
                  alt="password show"
                  onClick={() => handleTogglePasswordVisibility("oldPassword")}
                />
              ) : (
                <img
                  src={Eye}
                  className="password-icon"
                  alt="password-hide"
                  onClick={() => handleTogglePasswordVisibility("oldPassword")}
                />
              )}
            </div>
           
          </div>
          {touched.oldPassword && errors.oldPassword &&<div className="text-start d-flex mt-8"> <img src={ErrorIcon} className="mr-8" alt="error icon"/>
               <span className="text-danger-custom">{errors.oldPassword}</span></div>}
          <div className="password-input-container mt-16 form-floating mb-3 flex-grow-1 ">
            <input
              type={showNewPassword ? "text" : "password"}
              name="newPassword"
              value={values.newPassword}
              onChange={handleChange}
              placeholder=""
              className={`form-control ${touched.newPassword && errors.newPassword ? "danger-input" : ""}`}
            />
              <label htmlFor="password">Nouveau mot de passe</label>
            {showNewPassword ? (
              <img
                src={EyeClosed}
                className="password-icon"
                alt="password show"
                onClick={() => handleTogglePasswordVisibility("newPassword")}
              />
            ) : (
              <img
                src={Eye}
                className="password-icon"
                alt="password-hide"
                onClick={() => handleTogglePasswordVisibility("newPassword")}
              />
            )}
            
           
          </div>
          {touched.newPassword && errors.newPassword &&
             <div className="text-start d-flex mt-8"> <img src={ErrorIcon} 
             className="mr-8" alt="error icon"/>
               <span className="text-danger-custom">{errors.newPassword}</span>
               </div>}
          <div className="mt-8 d-flex align-items-start info">
               <img src={Info} alt='note icon' className="mr-12"/><p>
        
               <label>Le mot de passe doit contenir au moins : 8 caractères, une majuscule et un caractère spécial</label>
            </p>
          </div>
            

          <div className="text-end">
            <button type="submit" className="cta-primary-m mt-32">
              Enregistrer
            </button>
          </div>
        </Form>
        
      )}
     
    </Formik>
  );
};

export default ChangePasswordConnected;

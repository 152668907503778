import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/crumb.scss";

const Crumb = ({ breadcrumb }) => {
  return (
    <div className="crumb pt-16">
     <>
  <Link to="/">Accueil</Link>
  {breadcrumb
    ?.slice(0)
    ?.reverse()
    ?.map((breadcrumb, index, array) => (
      <React.Fragment key={breadcrumb.id}>
        {" / "}
        { index !== array.length - 1 ? (
          <Link to={`/category/${breadcrumb.id}`}>{breadcrumb.name}</Link>
        ) : (
          <span>{breadcrumb.name}</span>
        )}
      </React.Fragment>
    ))}
</>


    </div>
  );
};

export default Crumb;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import infoPersoActive from '../../assets/imgs/fi_user-profil.svg'
import infoPerso from '../../assets/imgs/user.svg'
import Business from '../../assets/imgs/ic_round-business.svg'
import BusinessActive from '../../assets/imgs/ic_round-business-active.svg'
import FiEdit from '../../assets/imgs/fi_edit.svg'
import FiEditActive from '../../assets/imgs/fi_edit-active.svg'
import ShoppingBag from '../../assets/imgs/fi_shopping-bag.svg'
import ShoppingBagActive from '../../assets/imgs/fi_shopping-bag-active.svg'
import Cart from '../../assets/imgs/fi_map.svg'
import CartActive from '../../assets/imgs/fi_map-active.svg'
import Payment from '../../assets/imgs/fi_credit-card.svg'
import PaymentActive from '../../assets/imgs/fi_credit-card-active.svg'
import LogOut from '../../assets/imgs/fi_log-out.svg'
import DisconnectModal from '../../components/disconnectModal';
const Sidebar = ({ page, setPage }) => {
  const navigate=useNavigate()
    const [open, setOpen] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    //1 = particulier
    const hasInformationEntreprise = localStorage.getItem('role') !== '1';

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleDisconnect = () => {
        setOpenModal(!openModal)
       
    };

    const hideModal = () => {
        setOpenModal(!openModal);
    };

    return (
        <div className={`sidebar-profil mt-8 ${open ? 'open' : 'closed'}`}>
            {openModal ? (
                <DisconnectModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                />
            ) : (
                ''
            )}
            <ul className="sub-sidebar mt-24">
                <li
                    className={`list-item ${page === 1 ? 'body-m' : ''}`}
                    onClick={() => setPage(1)}
                >
                    <img src={page === 1? infoPersoActive:infoPerso} alt="icon user" className='mr-8'/>
                   <span className='h-mobile'> Informations personnelles</span>
                </li>
                {hasInformationEntreprise && (
                  <li
                      className={`list-item ${page === 2 ? 'body-m' : ''}`}
                      onClick={() => setPage(2)}
                  >
                       <img src={page === 2 ? BusinessActive :Business} alt="icon info entreprise" className='mr-8'/>
                       <span className='h-mobile'>Informations entreprise</span>
                  </li>
                )}
                {/* <li
                    className={`list-item ${page === 2 ? 'body-m' : ''}`}
                    onClick={() => setPage(2)}
                >
                     <img src={Business} alt="icon info entreprise" className='mr-8'/>
                     <span className='h-mobile'>Informations entreprise</span>
                </li> */}
                <li
                    className={`list-item ${page === 3 ? 'body-m' : ''}`}
                    onClick={() => setPage(3)}
                >
                     <img src={page === 3 ?FiEditActive:FiEdit} alt="icon change password" className='mr-8'/>
                     <span className='h-mobile'> Changer mon mot de passe</span>
                </li>
                <li
                    className={`list-item ${page === 4 ? 'body-m' : ''}`}
                    onClick={() => setPage(4)}
                >
                     <img src={page === 4 ?ShoppingBagActive:ShoppingBag} alt="icon commandes" className='mr-8'/>
                     <span className='h-mobile'>Commandes</span> 
                </li>
                <li
                    className={`list-item ${page === 5 ? 'body-m' : ''}`}
                    onClick={() => setPage(5)}
                >
                     <img src={page === 5 ? CartActive:Cart} alt="icon cart" className='mr-8'/>
                     <span className='h-mobile'>Carnet d’adresse</span> 
                </li>
                <li
                    className={`list-item ${page === 6 ? 'body-m' : ''}`}
                    onClick={() => setPage(6)}
                >
                     <img src={page === 6 ?PaymentActive:Payment} alt="icon credit card" className='mr-8'/>
                     <span className='h-mobile'> Méthode de paiement</span>
                </li>
                {/* <li
                    className={`list-item ${page === 7 ? 'body-m' : ''}`}
                    onClick={() => setPage(7)}
                >
                     <img src={Euro} alt="icon euro" className='mr-8'/>
                     <span className='h-mobile'>Mon crédit</span>
                </li>
                <li
                    className={`list-item ${page === 8 ? 'body-m' : ''}`}
                    onClick={() => setPage(8)}
                >
                     <img src={Employees} alt="icon users" className='mr-8'/>
                     <span className='h-mobile'>Gestion employés</span>
                </li> */}
                <li className="list-item " onClick={handleDisconnect}>
                <img src={LogOut} alt="icon logout" className='mr-8'/>
                <span className='h-mobile body-s'>Se déconnecter</span> 
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;

import { useEffect, useState,useCallback } from "react";
import Services from "../services/global.service";
import CardAdr from "./cardAdr";
import AddAddress from "./addAdr";

const MyAdresses = () => {
  const [factAdr, setFactAdr] = useState([]);
  const [livAdr, setLivAdr] = useState([]);
  const [modalAdr, setModalAdr] = useState();
  const getMyAdresses = useCallback(() => {
    Services.getOne(localStorage.getItem("id"), "adresses")
      .then((res) => {
       console.log (res.data);
        const filteredAddresses = res.data.filter((adr) => adr.type === 1);
        const filteredFactAddresses = res.data.filter((adr) => adr.type === 2);
       
        setLivAdr(filteredAddresses);
        setFactAdr(filteredFactAddresses);
      })
      .catch((err) => {
        console.log(err);
      });
 }, []);
  const OpenAddModal = () => {
   
    setModalAdr(!modalAdr);
  };
  useEffect(() => {
    getMyAdresses();
  }, [modalAdr]);
  return (
    <>
      {modalAdr && <AddAddress  modalAdr setModalAdr={setModalAdr}  panier={false} />}
      <div className="profil min-vh-80  mx-auto mt-24">
        <div className="row container">
          <div className="col">
            {" "}
            <h5>Carnet d'adresse</h5>
          </div>
          <div className="col text-end">
            <button className="cta-primary-m" onClick={OpenAddModal}>
              Ajouter une adresse
            </button>
          </div>
        </div>
        <div className="row mt-32">
          <div className="col-md-6">
            <p className="subtitle-m">Adresse de facturation</p>
            {factAdr.map((adr) => (
              <CardAdr adr={adr} getMyAdresses={getMyAdresses}  />
            ))}
          </div>
          <div className="col-md-6">
            <p className="subtitle-m">Adresse de livraison</p>
            {livAdr.map((adr) => (
              <CardAdr adr={adr} getMyAdresses={getMyAdresses}  />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default MyAdresses;

import LayoutF from "./../components/LayoutF";
import imgnotFound from '../assets/imgs/404.svg'

const NotFoundRoute = () => {
  return (
    <LayoutF>
      <div className="bg-auth ">
        <div className="not-found-img min-vh-50 container d-flex align-items-center">
          <div className="row">
            <div className="col-md-8 text-start">
              <h5>Oh oh, la page que vous cherchez n’existe pas ...</h5>
              <p className="subtitle-s mt-8">Essaye d’utiliser notre moteur de recherche dans le menu du haut ou retourne à la page d’accueil.</p>
              <div className="mt-42">
                <a className="cta-primary-m" href="/">Retour à la page d’accueil</a>
              </div> 
            </div>
            <div className="col-md-6">
              {/* <img src={imgnotFound} alt=''></img> */}
            </div>
          </div>
        </div>
      </div>
    </LayoutF>
  );
};

export default NotFoundRoute;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Services from "../../services/global.service";
import LayoutF from "../../components/LayoutF";
import CardProduct from "../../components/cardProduct";
import Next from "../../assets/imgs/keyboard_arrow_right_24px.svg";

const References = () => {
    const params = useParams();
    const [marques, setMarques] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50); // Number of items per page
    const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;

    useEffect(() => {
        getMarques();
    }, []);

    const getMarques = () => {
        Services.getAll("marques")
            .then((res) => {
                console.log(res.data);
                const filteredData = res.data.filter(el =>  el?.image !== 'marques/' && el?.image!=="" && el?.image!==null);

                // Utilise les données filtrées
                setMarques(filteredData);
            })
            .catch((err) => {
                console.error("Error fetching marques:", err);
            });
    };

    // Calculate index of the first and last item on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentMarques = marques.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <LayoutF>
            <div className="bg-auth pb-32">
                <div className="container">
                    <h4 className="bleu800 pt-24">Nos marques</h4>
                    <div className="brands mt-32 row">
                        <div className="brand-row">
                        {currentMarques.map((data, index) => (
                                <div className="col" key={index}>
                                    <img src={IMG_URL + data.image} alt="marque img" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="pagination-marion mt-24 text-end">
                        {Array.from({ length: Math.ceil(marques.length / itemsPerPage) }, (_, i) => i + 1).map((pageNumber) => (
                            <button  className={`${currentPage === pageNumber ? 'bleu800 Exergue ' : ''}link-s`}  key={pageNumber} onClick={() => paginate(pageNumber)}>{pageNumber}</button>
                        ))}
                    </div>
                </div>
            </div>
        </LayoutF>
    );
};

export default References;

import FormSupplier from "../../components/formSupplier";
import LayoutF from "../../components/LayoutF";

const SupplierOrder=()=>{
    return(
      <LayoutF>
        <div className="bg-auth">
          <div className="container pt-32">
          <FormSupplier/> 
          </div>
        </div>
               
      </LayoutF>
    );
}
export default SupplierOrder
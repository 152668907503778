import Layout from "../../components/Layout";
import LayoutF from "../../components/LayoutF";
import FormRegister from "../../components/formRegister";
import './auth.scss'
const Register = () => {
  return (
    <Layout header={true}>
      <div className="row m-0 pt-24">
        <div className="col-md-4"></div>
        <div className="col-md-4 login-form">
          {" "}
          <h4 className="bleu600">Création de compte</h4>
          <p className="bleu600">Créer ton compte pour pouvoir te faire livrer tes matériaux en moins d'une heure sur ton chantier.</p>
          <p className="legend">Tu as déjà un compte ? <a className="link-s" href='/login'>Se connecter</a> </p>
          <FormRegister/>
        </div>
        <div className="col-md-4"></div>
      </div>
    </Layout>
  );
};
export default Register;

import LayoutF from "../../components/LayoutF";

const Politique = () => {
  return (
    <LayoutF>
      <div className="bg-auth">
        <div className="container pt-24">
          <h4 className="bleu800">Politique de Confidentialité</h4>
          <p className="bleu800 subtitle-m mt-16">
            Cette politique de confidentialité s’applique au site :
            www.app.marion-materiaux.fr Elle a été mis à jour le : 01 Avril 2024
            Dans le cadre de son activité, la société La société Marion, dont le
            siège social est situé au 559 Chemin du Hameau de Plan Sarrain 06370
            MOUANS SARTOUX, est amenée à collecter et à traiter des informations
            dont certaines sont qualifiées de « données personnelles ». La
            société Marion attache une grande importance au respect de la vie
            privée, et n’utilise que des données de manière responsable et
            confidentielle et dans une finalité précise.
          </p>
          <ol>
            <h5 className="bleu800 mt-40">
              <li className="text-start">
                Données Personnelles.
                <p className="bleu800-body-s mt-14">
                  Sur le site web www.app.marion-materiaux.fr, il y a 2 types de
                  données susceptibles d’être recueillies : Les données
                  transmises directement Ces données sont celles que vous nous
                  transmettez directement, via un formulaire de contact ou bien
                  par contact direct par email. Sont obligatoires dans le
                  formulaire de contact les champs « Prénom, Nom », « Entreprise
                  » et « Adresse Email ». Les données collectées automatiquement
                  Lors de vos visites, une fois votre consentement donné, nous
                  pouvons recueillir des informations de type « web analytics »
                  relatives à votre navigation, la durée de votre consultation,
                  votre adresse IP, votre type et version de navigateur. La
                  technologie utilisée est le cookie. Pour évaluer si notre
                  travail est efficace, nous faisons comme la plupart des sites
                  web, nous mesurons notre audience en utilisant Google
                  Analytics.
                </p>
              </li>
            </h5>

            <h5 className="bleu800">
              <li className="text-start">Utilisation des Données</li>
              <p className="bleu800-body-s mt-14">
                Les données que vous nous transmettez directement sont utilisées
                dans le but de vous re-contacter et/ou dans le cadre de la
                demande que vous nous faites. Les données « web analytics » sont
                collectées de forme anonyme (en enregistrant des adresses IP
                anonymes) par Google Analytics, et nous permettent de mesurer
                l’audience de notre site web, les consultations et les
                éventuelles erreurs afin d’améliorer constamment l’expérience
                des utilisateurs. Ces données sont utilisées par la société
                Marion, responsable du traitement des données, et ne seront
                jamais cédées à un tiers ni utilisées à d’autres fins que celles
                détaillées ci-dessus.
              </p>
            </h5>
            <h5 className="bleu800">
              <li className="text-start">Base Légale</li>
              <p className="bleu800-body-s mt-14">
                Les données personnelles ne sont collectées qu’après
                consentement obligatoire de l’utilisateur. Ce consentement est
                valablement recueilli (boutons et cases à cocher), libre, clair
                et sans équivoque.
              </p>
            </h5>
            <h5 className="bleu800">
              <li className="text-start">Durée de conservation</li>
              <p className="bleu800-body-s mt-14">
                Les données seront sauvegardées durant une durée maximale de 3
                ans.
              </p>
            </h5>
            <h5 className="bleu800">
              <li className="text-start">Liste des cookies</li>
              <p className="bleu800-body-s mt-14">
                Voici la liste des cookies utilisées et leur objectif. Cookies
                nécessaires Ce sont des cookies qui nous permettent d’être en
                mesure de vous proposer un bon fonctionnement du site web et qui
                vous permettent de faire usage des services que nous
                fournissons. Si ceux-ci sont bloqués, vous ne serez pas en
                mesure d’utiliser notre site web. Nom des cookies :
                cookieconsent_status Objectif : Permet de recueillir votre choix
                concernant l’utilisation de cookies sur notre site. Durée de
                validité : Expire après 1 an par défaut. Cookies statistiques
                Google Analytics : outil Google d’analyse d’audience Internet
                permettant aux propriétaires de sites Web et d’applications de
                mieux comprendre le comportement de leurs utilisateurs. Cet
                outil utilise des cookies pour collecter des informations et
                générer des rapports sur le trafic (nombre de visites, temps
                passé sur le site, nombre de pages vues en moyenne, appareil et
                navigateur utilisé, emplacement géographique), l’origine du
                trafic (sources et campagnes à l’origine des visites) et la
                navigation (pages consultées, actions réalisées). Nom des
                cookies : _ga Objectif : Permet de distinguer les utilisateurs.
                Durée de validité : Expire après 2 ans par défaut. Nom des
                cookies : _gid Objectif : Permet de distinguer les utilisateurs.
                Durée de validité : Expire après 24 heures par défaut. Nom des
                cookies : _gat Objectif : Permet de réduire le taux de requête.
                Durée de validité : Expire après 1 minute par défaut. En savoir
                plus.
              </p>
            </h5>
            <h5 className="bleu800">
              <li className="text-start">Opposition aux cookies</li>
              <p className="bleu800-body-s mt-14">
                Le refus d’installation d’un cookie peut entraîner
                l’impossibilité d’accéder à certains services. L’utilisateur
                peut toutefois configurer son ordinateur de la manière suivante,
                pour refuser l’installation des cookies : Sous Internet Explorer
                : onglet outil (pictogramme en forme de rouage en haut a droite)
                / options internet. Cliquez sur Confidentialité et choisissez
                Bloquer tous les cookies. Validez sur Ok. Sous Firefox : en haut
                de la fenêtre du navigateur, cliquez sur le bouton Firefox, puis
                aller dans l’onglet Options. Cliquer sur l’onglet Vie Privée.
                Paramétrez les Règles de conservation sur : utiliser les
                paramètres personnalisés pour l’historique. Enfin décochez-la
                pour désactiver les cookies. Sous Safari : Cliquez en haut à
                droite du navigateur sur le pictogramme de menu (symbolisé par
                un rouage). Sélectionnez Paramètres. Cliquez sur Afficher les
                paramètres avancés. Dans la section « Confidentialité », cliquez
                sur Paramètres de contenu. Dans la section « Cookies », vous
                pouvez bloquer les cookies. Sous Chrome : Cliquez en haut à
                droite du navigateur sur le pictogramme de menu (symbolisé par
                trois lignes horizontales). Sélectionnez Paramètres. Cliquez sur
                Afficher les paramètres avancés. Dans la section «
                Confidentialité », cliquez sur préférences. Dans l’onglet «
                Confidentialité », vous pouvez bloquer les cookies.
              </p>
            </h5>
            <h5 className="bleu800">
              <li className="text-start">Droits de l’utilisateur</li>
              <p className="bleu800-body-s mt-14">
                L’utilisateur possède le droit de prendre connaissance, de
                mettre à jour, de modifier ou de supprimer les renseignements
                qui le concernent. Pour cela, l’utilisateur peut contacter la
                société Marion par email : contact@marion-materiaux.fr en
                précisant l’objet de sa demande.
              </p>
            </h5>
            <h5 className="bleu800">
              <li className="text-start">
                Contact délégué à la protection des données
              </li>
              <p className="bleu800-body-s mt-14">
                La société Marion – contact@marion-materiaux.fr
              </p>
            </h5>
            <h5 className="bleu800">
              <li className="text-start">
                Conditions de modification de la Politique de Confidentialité
              </li>
              <p className="bleu800-body-s mt-14 pb-16">
                La présente politique de confidentialité peut être consultée à
                tout moment à l’adresse suivante : https://
                www.app.marion-materiaux.fr/ La société Marion se réserve le
                droit de la modifier afin de garantir sa conformité avec le
                droit en vigueur. Par conséquent, l’utilisateur est invité à
                venir consulter régulièrement cette politique de confidentialité
                afin de se tenir informé des derniers changements qui lui seront
                apportés.
              </p>
            </h5>
          </ol>
        </div>
      </div>
    </LayoutF>
  );
};
export default Politique;

import Map from "../assets/imgs/fi_map-pin-red.svg";
import Clock from "../assets/imgs/fi_clock.svg";
import Liv from "../assets/imgs/liv.svg";
import Star from "../assets/imgs/star.svg";
const HeroSection = () => {
    const avis={
        starNb:5,
        User:'Julien',
        function:'Gérant de PROVENCE CONSTRUCTION',
        text:"Marion a révolutionné notre façon de travailler ! Fini les allers-retours chez les fournisseurs de matériaux – tout est livré directement sur nos chantiers. Un gain de temps qui nous permet de booster notre productivité et nous concentrer sur ce que nous faisons de mieux : travailler! "
    }
  return (
    <div className="row hero-section">
        <div className="col-md-5">
        <h1 className="bleu500">Tu travailles,<br/> nous livrons<span className="pt-color">.</span></h1>
        <p className="subtitle-m mt-40"><img src={Clock} alt="clock icon" className="mr-8" />Livraison à la demande des matériaux</p>
        <p className="subtitle-m"> <img src={Map} alt="clock icon" className="mr-8" />Directement sur ton chantier</p>
        <p className="subtitle-m"> <img src={Liv} alt="clock icon"  className="mr-8"/>Suivi  de ta livraison</p>
      </div>
      <div className="col-md-7 illu">
        <div className="card-avis">
            <div className="stars text-start">
            {Array.from({ length: avis.starNb }, (_, index) => (
                <img src={Star} alt="star icon" className="mx-1"/>
            ))}
            </div>
            <p className="bleu600 mt-8">{avis?.function} - {avis?.User} </p>
            <p className="legend bleu600 mt-8">“{avis?.text}”</p>
        </div>
      </div>
    </div>

  );
};
export default HeroSection;

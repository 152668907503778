import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { Field, Formik } from "formik";
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import * as Yup from "yup";
import ErrorIcon from '../assets/imgs/fi_alert-triangle.svg'
import Services from "../services/global.service";
const validationSchema = Yup.object().shape({
   prenom: Yup.string().required("Prénom est requis"),
   nom: Yup.string().required("Nom est requis"),
  telephone: Yup.string()
    .required("Numéro de téléphone est requis")
    .matches(/^(06|07)\d{8}$/, "Numéro de téléphone invalide"),
});
const AddAddress = ({ modalAdr, setModalAdr,panier }) => {
  const [address, setAddress] = useState("");
  const [Error, setError] = useState("");
  const [latLong, setLatLong] = useState(null);
  const [cart, setCart] = useState();

  const handleChangeAddress = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    setAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;

      // Extract necessary address components
      const postalCode = addressComponents.find(component => 
        component.types.includes('postal_code')
      );
      const street = addressComponents.find(component => 
        component.types.includes('route')
      );
      if (postalCode && street) {
        console.log('Address is complete:', postalCode.long_name, street.long_name);
  
        const latLng = await getLatLng(results[0]);
      if (latLng) {
        setLatLong(latLng);
      } else {
        console.error("Error: Unable to get latitude and longitude");
      }
    }else {
      console.error('Error: Incomplete address. Missing postal code or street.');
    }
    } catch (error) {
      console.error("Error:", error);
    }
  };
 
  const handleClose = () => {
    setModalAdr(false);
  };
  const getMyCart = () => {
    Services.getAll("panier")
      .then((res) => {
       // console.log("res my cart in add adr", res);
        setCart(res.data?.panier);
       
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getMyCart();
  
  }, []);
  const HandleSaveAdr = (values) => {
    // Handle saving address logic here
    //console.log(values); // This will log the form values when Save is clicked
    const data = {
      latitude: latLong?.lat,
      longitude: latLong?.lng,
    };
    if(!address || !latLong?.lat){
      setError('Adresse requis') ;
      return;
    }
    //console.log("data", data);
    if(address){
       Services.create(data, "zones/adresse")
      .then((res) => {
        //console.log(res.data);
        const adr={
            ...data,
            ...values,
            adresse:address,
            type:1,
            user:localStorage.getItem('id')
        }
       // console.log(adr)
        if(res.data){
            Services.create(adr,'adresses/create')
            .then((res)=>{
               // console.log(res.data)
                Services.getAll(`addresses/${localStorage.getItem('id')}/update-default-livraison-address/${res.data.adresse.id}`)
                .then((res)=>{
                  console.log(res)
                  setModalAdr(false)
                })
                .catch((err)=>{console.log(err)})
                
            
            })
            .catch((err)=>console.log(err))
        }
        else{
          setError('Adresse non prise en charge')
        }
        // setCheck(res.data)
        //add adresse set localstorage adr
      })
      .catch((err) => {
        console.log(err);
      });
    }
   

   
  };
  const searchOptions = {
    componentRestrictions: { country: ['fr'] },
    types: ['address']
  }
  return (
    <Modal show={modalAdr} onHide={handleClose} className="modal-marion">
      <Modal.Header closeButton>
        <h6>Adresse de livraison</h6>
      </Modal.Header>
      <Modal.Body>
         {cart ?
        <>
        <div className="error-adr">
            <p className="m-0">Attention, si tu modifies ton adresse de livraison et 
                que celle-ci est située à une distance trop importante, les articles actuellement 
                dans ton panier pourraient être perdus. N'oublie pas de 
                les sauvegarder dans tes favoris avant d'apporter des changements.</p>
        </div>
        </> :''}
        <Formik
          initialValues={{
            name: "",
            gender: "madame",
            prenom: "",
            nom: "",
            telephone: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => HandleSaveAdr(values)}
        >
          {({ errors,handleSubmit,touched,values }) => (
            <Form onSubmit={handleSubmit} className="mt-40">
            
              <div className="row">
                <div className="col-md-7">
                  {" "}
                  <div className="form-floating mb-3">
                    <Field
                      type="text"
                      name="name"
                      className="form-control"
                      id="floatingInputTitre"
                      placeholder=""
                    />
                    <label htmlFor="floatingInputTitre">
                      Titre de l'adresse (ex: Chantier Nice)
                    </label>
                  </div>
                </div> 
                <div className="col-md-6"></div>
              </div>
              
              <div className="row">
                <div className="col-md-6 d-flex">
                  {" "}
                  <div className="form-check mr-16">
                    <Field
                      type="radio"
                      name="gender"
                      value="madame"
                      id="madame"
                      className="form-check-input"
                      defaultChecked
                    />
                    <label htmlFor="madame" className="form-check-label bleu700-body-s">
                      Madame
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      type="radio"
                      name="gender"
                      value="monsieur"
                      id="monsieur"
                      className="form-check-input"
                    />
                    <label htmlFor="monsieur" className="form-check-label bleu700-body-s">
                      Monsieur
                    </label>
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>


              <div className="row mt-16">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <Field
                      type="text"
                      name="prenom"
                      className={`form-control ${touched.prenom && errors.prenom ? "danger-input" : ""}`}

                      id="floatingInputPrenom"
                      placeholder=""
                    />
                    <label htmlFor="floatingInputPrenom">Prénom</label>
                  </div>
                </div>
                <div className="col-md-6">
                  {" "}
                  <div className="form-floating mb-3">
                    <Field
                      type="text"
                      name="nom"
                      className={`form-control ${touched.nom && errors.nom ? "danger-input" : ""}`}
                      id="floatingInputNom"
                      placeholder=""
                    />
                    <label htmlFor="floatingInputNom">Nom</label>
                  </div>
                </div>
                {touched.nom && errors.nom && <div className="text-start mt-8 d-flex">
                     <img src={ErrorIcon} className="mr-8" alt="error icon"/>
                   <span className="text-danger-custom">{errors.nom}</span></div>}
              </div>

              <div className="row mt-16">
                <div className="col-md-6">
                  {" "}
                  <div className="form-floating mb-3">
                    <Autocomplete
                      value={address}
                      className=""
                       searchOptions={searchOptions}

                      onChange={handleChangeAddress}
                      onSelect={handleSelect}
                      onPlaceSelected={(place) => {
                      //  console.log(place);
                      }}
                      
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="form-floating mb-3">
                           <input
                    {...getInputProps({
                      placeholder: "",
                      className:`form-control ${ Error!==""  ? "danger-input" : ""}`
                    })}
                    id="floatingAdr"
                  />
                    <label htmlFor="floatingAdr">Adresse</label>
                          <div
                            className={
                              suggestions.length > 0 && `container-autocomplete`
                            }
                          >
                            {loading ? <div>Loading...</div> : null}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#41B6E6"
                                  : "#fff",
                              };
                              return (
                                <div
                                  className="mapItem"
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </Autocomplete>
                    <div className="text-danger-custom">{Error}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  {" "}
                  <div className="form-floating mb-3">
                    <Field
                      type="text"
                      name="telephone"
                      className={`form-control ${touched.telephone && errors.telephone ? "danger-input" : ""}`}
                      id="floatingInputTelephone"
                      placeholder=""
                    />
                    <label htmlFor="floatingInputTelephone">
                      Numéro de téléphone
                    </label>
                  </div>
                  {touched.telephone && errors.telephone && <div className="text-start mt-8 d-flex">
                     <img src={ErrorIcon} className="mr-8" alt="error icon"/>
                   <span className="text-danger-custom">{errors.telephone}</span></div>}
                </div>
              </div>

              <div className="text-end mt-24">
                <button className="cta-secondary-m mr-8" onClick={handleClose}>
                  Annuler
                </button>
                <button type="submit" className="cta-primary-m" onClick={HandleSaveAdr}>
                  Enregistrer cette adresse
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddAddress;

import LayoutF from "../../components/LayoutF";
import HeroSection from "../../components/heroSection";
import NosMarques from "../../components/nosMarques";
import "./home.scss";
const Home = () => {
  return (
    <LayoutF>
      <div className=" bg-auth bg-hero">
        <div className="container pt-60">
          <HeroSection />
        </div>
      </div>
      <div className="bg-auth mt-24">
        <NosMarques />
      </div>
    </LayoutF>
  );
};
export default Home;

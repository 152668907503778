import { useEffect, useState } from 'react';
import AuthService from '../services/auth.service';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Services from '../services/global.service';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import AddCard from './addCard';
import { loadStripe } from "@stripe/stripe-js";
import CarteBleu from '../assets/imgs/cb.png'
import DeleteIcon from "../assets/imgs/fi_trash.svg";
import Check from '../assets/imgs/fi_check-orange.svg'

const pk=process.env.REACT_APP_STRIPE_PK
const stripePromise = loadStripe(pk);
const PaymentMethods = () => {
    const [cards, setCards] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [cardDefault, setCardDefault] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [setupIntent, setSetUpIntent] = useState("");
   
      const DeleteCard=(id)=>{
        Services.create({card_id:id},'delete-card')
        .then((res)=>
        {
          console.log(res.data);
          getAllCards();
        
      }
        )
        .catch((err)=>console.log(err))
      }
      const defaultCard=(id)=>{
        console.log('heyyy',id)
        Services.create({card_id:id},'set-default-card')
        .then((res)=>
        {
          console.log(res.data);
          getDefaultCard()
        
      }
        )
        .catch((err)=>console.log(err))
      }
const getAllCards=()=>{
Services.getAll('getCards')
.then((res)=>{
    console.log(res?.data)
    setCards(res.data)
})
.catch((err)=>{console.log(err)})
}
const PaymentIntent = () => {
    Services.create({}, "setup-intent")
      .then((res) => {
        console.log(res);
        setClientSecret(res?.data?.setupIntent);
        setSetUpIntent(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
const getDefaultCard=()=>{
  Services.getAll('getDefaultCard')
  .then((res)=>{
    console.log('default',res?.data?.default_card)
    setCardDefault(res?.data?.default_card?.id)
  })
  .catch((err)=>{console.log(err)})
}  
  useEffect(() => {        
    PaymentIntent() 
  }, []);
    useEffect(() => {
        getAllCards()
        getDefaultCard()
    }, [addModal]);

    // Wait for info to be set before rendering the form


    return (
        <div className="profil min-vh-80 col-md-12 mx-auto mt-24">
            <AddCard addModal={addModal} setAddModal={setAddModal} clientSecret={clientSecret} setupIntent={setupIntent}/>
           <div className="d-flex justify-content-between align-items-center">
           <div className='col-6'><h5>Méthode de paiement</h5>
            </div> 
            <div className='col-6'>
                <button className="cta-primary-m" onClick={()=>{setAddModal(!addModal)}}>
            Ajouter une nouvelle méthode de paiement
            </button> 
            </div>
           
           </div>
            
          
            <div className='mt-24 '>
                {cards.map((card)=>(
                    
                 <div className=" card-payment w-50 mt-8">
                    <div className="d-flex">
                        <div className='mr-8'>
                            <img src={CarteBleu} alt="carte bleu"/>
                        </div>
                        <div>
                            <p className="bleu700-body-s">Carte finissant par {card?.last4}</p>
                        </div>

                    </div>
                    <div> {cardDefault=== card?.card_id && <p className="legende bleu700"><img src={Check} alt="check icon" className='mr-8'/>Méthode de paiement utilisé par défaut</p>}</div>
                    <div className="text-end">
                      {cardDefault!== card?.card_id &&
                       <button className='link-s' onClick={() => defaultCard(card?.card_id)}>Utiliser comme méthode de paiement par défaut</button>}
                    <button className="link-s" onClick={() => DeleteCard(card?.card_id)}>
                {" "}
                <img src={DeleteIcon} alt="delete icon" />
              </button>
                    </div>
                </div> 
                )  
                )}
            
            </div>
        </div>
    );
};

export default PaymentMethods;

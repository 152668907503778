import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { useState } from "react";
import EyeClosed from '../../assets/imgs/fi_eye-off.svg'
import Eye from '../../assets/imgs/fi_eye.svg'
import Services from "../../services/global.service";
import Info from '../../assets/imgs/fi_info.svg'

const PasswordChang = () => {
  const location = useLocation();
  const { state } = location;
  const email = state && state.email;
  const code = state && state.code;
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const handleToggle = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
    return regex.test(password);
  };

  const changePass = () => {
    if (!validatePassword(password)) {
      setError("Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un caractère spécial.");
      return;
    }

    const data = {
      email,
      password,
      code
    };
    console.log('data', data);
    
    Services.create(data, 'update-password')
      .then((res) => {
        console.log('res', res);
        navigate("/login");
      });
  };

  return (
    <>
      <Layout header={true}>
        <div className="row m-0 pt-60 ">
          <div className="col-md-4"></div>
          <div className="col-md-4 p-24 reset-form">
            <h4 className='bleu600'>Crée ton nouveau mot de passe</h4>
            
            <div className="password-input-container form-floating mb-3 flex-grow-1">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError(""); // Clear the error message when the password changes
                }}
                className={`form-control ${error ? 'danger-input' : ''}`}
                id="password"
                placeholder=""
              />
              <label htmlFor="password">Mot de passe</label>
              <div className="password-toggle-icon">
                <img src={showPassword ? Eye : EyeClosed} className="password-icon" alt="password toggle" onClick={handleToggle} />
              </div>
            </div>
            {error && <div className="text-danger">{error}</div>}
            <div className="mt-8 d-flex align-items-start info">
              <img src={Info} alt='note icon' className="mr-12" />
              <p>Le mot de passe doit contenir au moins : 8 caractères, une majuscule et un caractère spécial</p>
            </div>
            <button className="cta-primary-m w-100 mt-24" onClick={changePass}>
              Je me connecte
            </button>
          </div>
          <div className="col-md-4"></div>
        </div>
      </Layout>
    </>
  );
};

export default PasswordChang;

import './profil.scss';
import { useMyContext } from "../../context"; // Import the context
import { useState, useEffect } from "react";
import Sidebar from "./sidebar";
import Information from '../../components/informations';
import { useParams } from "react-router-dom";
import LayoutF from "../../components/LayoutF";
import ChangePasswordConnected from '../../components/changePasswordProfil';
import InformationEntreprise from '../../components/informationsEntreprise';
import MesCommandes from '../../components/mesCommandes';
import MyAdresses from '../../components/adressesCarnet';
import PaymentMethods from '../../components/paymentMethods';
import OrderDetails from '../../components/orderDetails';

const Profil = () => {
    const params = useParams();
    console.log('param', params.id)
    const [page, setPage] = useState(parseInt(params?.id) || 1);
    const { userData } = useMyContext(); // Get user data from context


    let pageComponent;

    
        switch (page) {
            case 1:
                pageComponent = <Information />;
                break;
            case 2:
                pageComponent = <InformationEntreprise />;
                break;
            case 3:
                pageComponent = <ChangePasswordConnected />;
                break;
            case 4:
                pageComponent = <MesCommandes />;
                break;
            case 5:
                pageComponent = <MyAdresses />;
                break;
            case 6:
                pageComponent = <PaymentMethods />;
                break;
            case 7:
                pageComponent=<OrderDetails />;
                break;
        
            default:
                pageComponent=<OrderDetails />;
        }

    useEffect(() => {
        // You can also include additional logic here if needed
    }, [page, userData]); // Add userData as a dependency to re-run the effect when userData changes

    return (
        <LayoutF>
            <div className="container-fluid min-vh-80 bg-auth">
                <div className="row ">
                    <div className="col-auto">
                        <Sidebar page={page} setPage={setPage} />
                    </div>
                    <div className="col w-sm-100 mt-48 container">
                        {pageComponent}
                    </div>
                </div>
            </div>
        </LayoutF>
    );
}

export default Profil;

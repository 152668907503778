import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Services from "../../services/global.service";
import LayoutF from "../../components/LayoutF";
import CardProduct from "../../components/cardProduct";
import Next from "../../assets/imgs/keyboard_arrow_right_24px.svg";

const ProductByMarque=()=>{
    const params=useParams();
    const [products,setProducts]=useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortingOption, setSortingOption] = useState("asc");
    const [perPage, setPerPage] = useState(10); // Number of items per page
    const perPageOptions = [5, 10, 20, 50];
    const getProductsByMarque=()=>{
        Services.getOne(params.id,'products/marque')
        .then((res)=>
        {
            console.log(res.data);
            setProducts(res?.data?.products)
            setTotalPages(res?.data?.pagination?.last_page);
        }
        )
        .catch((err)=>{console.log(err)})
    }
    const handlePerPageChange = (e) => {
        setPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Reset to first page when changing items per page
      };
      const handlePrevPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
      const handleNextPage = () => {
        console.log("here");
        if (currentPage < totalPages) {
          console.log("coucou");
          setCurrentPage(currentPage + 1);
        }
      };
    useEffect(()=>{getProductsByMarque()},[])
return(
<LayoutF>
<div className="bg-auth">
        <div className="container">
          
          <h4 className="bleu800 pt-24">{products && products[0]?.product_marque?.name}</h4>
        
          <div className="row mt-16">
            {products &&
              products?.map((item) =>(
            
                    <CardProduct key={item.id} data={item} />
                  
                )
              )
              }
         
         {products?.length >0 ?   <div className="row d-flex mb-5 ">
                <div className="col-6 text-start">
                  <select value={perPage} onChange={handlePerPageChange}>
                    {perPageOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>{" "}
                  éléments par page
                </div>
                <div className="col-6 text-end">
                  {" "}
                  <span>
                    {" "}
                    {currentPage} / {totalPages}
                  </span>
                  <button
                    onClick={handlePrevPage}
                    className={`link-s closed ${
                      currentPage === 1 ? "" : "link-pag"
                    }`}
                    disabled={currentPage === 1}
                  >
                    <img src={Next} alt="icon pagination" />
                  </button>
                  <button
                    onClick={handleNextPage}
                    className={`link-s ${
                      currentPage === totalPages ? "" : "link-pag"
                    }`}
                    disabled={currentPage === totalPages}
                  >
                    <img src={Next} alt="icon pagination" />
                  </button>
                </div>
              </div>
                :'no items found'  }
          </div>
        </div>
      </div>
</LayoutF>
)
}
export default ProductByMarque;
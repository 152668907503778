import { Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

const AccessibleOrNot = ({
  accessibleModal,
  setAccessibleModal,
  check,
  connected,
}) => {
  const navigate = useNavigate();
  const handleClose = () => {
    setAccessibleModal(false);
  };
  const handleLoginClick = () => {
    const path = window.location.pathname;
    localStorage.setItem("redirectAfterLogin", path);
    navigate("/login");
  };
  const handleInscriptionClick = () => {
    const path = window.location.pathname;
    localStorage.setItem("redirectAfterLogin", path);
    navigate("/register");
  };

  return (
    <Modal show={accessibleModal} onHide={handleClose} className="modal-marion">
      <Modal.Header closeButton>
        <h6 className="bleu700">
          {check
            ? "Super, nous livrons à ton adresse !"
            : "Désolé, nous ne livrons pas encore dans cette zone"}
        </h6>
      </Modal.Header>
      <Modal.Body>
        <p className="bleu700">
          {check && connected
            ? "Connecte-toi pour accéder aux prix des produits qui t’intéressent."
            : check && !connected
            ? ""
            : ""}
        </p>
        {check && connected ? (
          <>
            {/* <div className="d-grid gap-2"> <a className="cta-primary-m w-100"  size="lg" href="/login">Me connecter</a></div>   */}
            <div className="d-grid gap-2">
              <Button
                className="cta-primary-m w-100"
                size="lg"
                onClick={handleLoginClick}
              >
                Me connecter
              </Button>
            </div>
            <div className="d-grid gap-2">
              <Button
                className="cta-secondary-m w-100 mt-16"
                onClick={handleInscriptionClick}
              >
                Créer mon compte
              </Button>
            </div>
          </>
        ) : check && !connected ? (
          <>
            <button className="cta-primary-m mt-24 w-100" onClick={handleClose}>
              Valider
            </button>
          </>
        ) : (
          <>
            <button className="cta-primary-m mt-24 w-100" onClick={handleClose}>
              Valider
            </button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AccessibleOrNot;

import axios from "axios";
const API_URL = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem("token");
console.log("my token", token);
const headersFiles = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + token,
  },
};
const login = (email, password) => {
  return axios
    .post(API_URL + "login", {
      email: email,
      password: password,
    })
    .then((response) => {
      if (response) {
        console.log(response.data.data.token);
        localStorage.setItem("role", response.data.data.user.role);
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("id", response.data.data.user.id);
        localStorage.setItem("nom", response.data.data.user.nom);
        localStorage.setItem("prenom", response.data.data.user.prenom);
     
      }
      return response.data;
    })
    .catch((err) => {
      console.log("erreur login", err);
      throw err; // Throw the error to be caught in the promise chain
    });
};
//change after reset
const changePassword = (token, password) => {
  //console.log(token);
  return axios
    .post(API_URL + "auth/reset/change", {
      token: token,
      new_password: password,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("erreur chnagepass", err);
      return err;
    });
};
const resetPassword = (email) => {
  return axios
    .post(API_URL + "auth/reset", {
      email: email,
    })
    .then((response) => {
      if (response) {
        console.log(response.data);
      }
      return response.data;
    })
    .catch((err) => {
      console.log("erreur login", err);
      return err;
    });
};
const getCurrentUser = () => {
  const id = localStorage.getItem("id");
  if (id) return id;
};
const profil =async () => {
  //const userId = getCurrentUser();
 
    return axios
      .get(API_URL + "profil", headersFiles)
      .then((response) => {
        console.log(response)
        return response.data;
      })
      .catch((err) => {
        return null;
      });
  
};
const checkMail = (email) => {
  return axios
    .post(API_URL + "email", {
      email: email,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return null;
    });
};
//chnage when i m connected and i know my password
const PasswordChange = async (data) => {
  const user = await profil();
  const datas = {
    current_password: data.oldPassword,
    password: data.newPassword,
    password_confirmation: data.newPassword,
  };
  console.log(datas);
  return axios
    .post(API_URL + "auth/update-password", datas, headersFiles)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("erreur chnagepass", err);
      return err;
    });
};
const AuthService = {
  profil,
  login,
  resetPassword,
  changePassword,
  PasswordChange,
  checkMail,
};

export default AuthService;
